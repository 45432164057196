import {
  Button,
  ButtonGroup,
  Link,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  Box,
} from "@mui/material";
import ApplicationBreadCrumbs from "components/ApplicationBreadCrumbs";
import ProductCatalogsDataGrid from "components/ProductCatalogs/ProductCatalogsDataGrid";
import { Route, Routes, Link as RouterLink, Outlet, useLocation } from "react-router-dom";
import { Crumb } from "types/crumb";
import { useMemo, useRef, useState } from "react";
import UploadPricingModal from "components/ProductCatalogs/UploadPricingModal";
import { ArrowBack, ArrowDropDown } from "@mui/icons-material";
import useTableExport from "hooks/useTableExport";
import { ProductCatalog } from "types/productCatalog";
import { fetcher } from "services/api.service";
import WholesaleIndividualProductCatalogModal from "components/ProductCatalogs/WholesaleIndividualProductCatalogModal";
import useSWR from "swr";
import { useFeatureFlag } from "hooks/useFeatureFlag";

const IndexElement = ({ crumbs }: { crumbs: Crumb[] }) => {
  const [open, setOpen] = useState(false);
  const { isLoading, confirmExportModal, setOpenConfirmModal } = useTableExport({ url: "/product_catalogs_export" });
  const [actionsOpen, setActionOpen] = useState(false);
  const actionButtonGroupAnchorRef = useRef<HTMLDivElement>(null);
  const { enabled: wholesaleEnabled } = useFeatureFlag("product_catalogs_wholesale");
  const handleActionButtonOpenToggle = () => {
    setActionOpen((prevActionOpen) => !prevActionOpen);
  };

  return (
    <>
      {open && <UploadPricingModal open={open} setOpen={setOpen} />}
      {confirmExportModal}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ApplicationBreadCrumbs crumbs={crumbs} />
        </Grid>

        <Grid container justifyContent="flex-end" xs={6} sx={{ my: 1 }}>
          <>
            <ButtonGroup variant="contained" ref={actionButtonGroupAnchorRef}>
              <Button variant="contained" disableElevation disableRipple disableFocusRipple>
                Actions
              </Button>
              <Button onClick={handleActionButtonOpenToggle}>
                <ArrowDropDown />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={actionsOpen}
              anchorEl={actionButtonGroupAnchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: "bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => setActionOpen(false)}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        <MenuItem onClick={() => setOpenConfirmModal(true)} disabled={isLoading}>
                          Export Product Catalog
                        </MenuItem>
                        <MenuItem onClick={() => setOpen(true)}>Upload eBay Prices</MenuItem>
                        {wholesaleEnabled && (
                          <MenuItem>
                            <Link component={RouterLink} underline="none" color="inherit" to="wholesale">
                              Reserve Wholesale Lot
                            </Link>
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        </Grid>
        <ProductCatalogsDataGrid wholesaleMode={false} />
      </Grid>
    </>
  );
};

const WholesaleElement = ({ crumbs }: { crumbs: Crumb[] }) => {
  const [wholesaleProductCatalogs, setWholesaleProductCatalogs] = useState<ProductCatalog[]>([]);
  const location = useLocation();
  const { data } = useSWR<{ productCatalogs: ProductCatalog[]; meta: { count: number } }>(
    () => "product_catalogs" + location.search,
    fetcher,
  );
  const showSelectAllBtn = useMemo(() => {
    return data?.productCatalogs.length === wholesaleProductCatalogs.length;
  }, [data?.productCatalogs, wholesaleProductCatalogs.length]);

  return (
    <>
      <Outlet context={{ wholesaleProductCatalogs, totalProductCatalogs: data?.meta.count }} />
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mb: 3 }}>
          <ApplicationBreadCrumbs crumbs={crumbs} sx={{ mb: 2 }} />
          <Typography>Filter and select the items you'd like to reserve for wholesalse</Typography>
        </Grid>

        <Grid container justifyContent="flex-end" xs={6} sx={{ my: 1 }}>
          <Box display="flex" alignItems="center">
            <Button variant="text" sx={{ color: "#000" }} startIcon={<ArrowBack />}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 1 }}
              disabled={wholesaleProductCatalogs.length < 1}
              component={RouterLink}
              to={{
                pathname: "confirm",
                search: `${location.search}`,
              }}
            >
              Continue
            </Button>
          </Box>
        </Grid>
        <Box width="100%">
          {showSelectAllBtn && (
            <Box sx={{ background: "rgb(227, 242, 253)" }} display="flex" justifyContent="center">
              <Button
                variant="text"
                color="primary"
                sx={{ ml: 1 }}
                component={RouterLink}
                to={{
                  pathname: "confirmAll",
                  search: `${location.search}`,
                }}
              >
                Select ALL {data?.meta.count} Product Catalogs
              </Button>
            </Box>
          )}
          <ProductCatalogsDataGrid wholesaleMode={true} setWholesaleProductCatalogs={setWholesaleProductCatalogs} />
        </Box>
      </Grid>
    </>
  );
};

export default function ProductCatalogsPage() {
  const crumbs: Crumb[] = [{ name: "Product Catalogs", path: "/product_catalogs" }];
  const wholesaleCrumbs: Crumb[] = [...crumbs, { name: "Reserve Wholesale Lot", path: "wholesale" }];

  return (
    <Routes>
      <Route index element={<IndexElement crumbs={crumbs} />} />
      <Route path="/wholesale" element={<WholesaleElement crumbs={wholesaleCrumbs} />}>
        <Route path="confirm" element={<WholesaleIndividualProductCatalogModal />} />
        <Route path="confirmAll" element={<WholesaleIndividualProductCatalogModal filterMode={true} />} />
      </Route>
    </Routes>
  );
}
