import { StyledEngineProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { App } from "App";
import ReactDOM from "react-dom";
import "./assets/index.css";
import { AuthProvider } from "hooks/useAuth";
import { SWRConfig } from "swr";
import { fetcher } from "services/api.service";

const licenseKey = process.env.REACT_APP_MUI_PRO || "";
LicenseInfo.setLicenseKey(licenseKey);

const dsn = process.env.REACT_APP_SENTRY_DSN;
if (dsn) {
  const rate = parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE || "1.0");
  Sentry.init({
    dsn,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "production",

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: rate,
  });
}
declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
  }
}
declare module "@mui/material/Radio" {
  interface RadioPropsColorOverrides {
    neutral: true;
  }
}

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <AuthProvider>
      <SWRConfig value={{ fetcher: fetcher }}>
        <App />
      </SWRConfig>
    </AuthProvider>
  </StyledEngineProvider>,
  document.getElementById("root"),
);
