import { ComponentProps, useMemo } from "react";
import useSWR from "swr";
import { ServerErrorOverlay } from "components/DataGrid/helpers/ServerErrorOverlay";
import { DataGridPro, GridNoRowsOverlay, GridToolbar } from "@mui/x-data-grid-pro";
import { serializeSortModel } from "components/DataGrid/helpers/serializers";
import { LabelDisplayedRowsArgs, LinearProgress } from "@mui/material";
import { fetcher } from "services/api.service";
import { filterModelToElasticQueryString } from "components/DataGrid/helpers/elasticSerializers";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import {
  deserializeElasticQueryString,
  deserializeElasticSortString,
} from "components/DataGrid/helpers/elasticDeserializers";
import { DataGridParamsOptions, useElasticDataGridParams } from "components/DataGrid/helpers/useElasticDataGridParams";
import { withBulkActions } from "components/DataGrid/behaviors/withBulkActions";
import { withPersistableSettings } from "components/DataGrid/behaviors/withPersistableSettings";

export interface ElasticDataGridProps extends DataGridParamsOptions {
  url: string;
  updateRowUrl?: (id: string) => string;
}

interface Hit<T> {
  Id: string;
  Index: string;
  Source: Record<string, T>;
}

export interface ElasticSearchResult<T> {
  hits: {
    total: { value: number; relation: string };
    hits: Hit<T>[];
  };
}

const MAX_RESULTS = 10000;

// Updates the Pagination label to show a + at the end if the count is greater than MAX_RESULTS
const ElasticAwarePaginationLabel = (props: LabelDisplayedRowsArgs) => {
  const { from, to, count } = props;
  return `${from}-${to} of ${count <= MAX_RESULTS ? count : `${MAX_RESULTS}+`}`;
};

const ComposedDataGrid = withBulkActions(withPersistableSettings(DataGridPro));

export const ElasticDataGrid = ({
  syncStateWithSearchParams,
  defaultToMostRecentParams,
  url,
  ...props
}: ElasticDataGridProps & Omit<ComponentProps<typeof ComposedDataGrid>, "rows">) => {
  const [params, setParams] = useElasticDataGridParams(props.initialState, {
    syncStateWithSearchParams,
    defaultToMostRecentParams,
  });

  const { data, isLoading, error, mutate } = useSWR<ElasticSearchResult<unknown>>(
    () => {
      const { page, size, query, sort } = params;
      const searchParams = new URLSearchParams({
        q: query.length === 0 ? "*" : query,
        from: ((page - 1) * size).toString(),
        size: size.toString(),
        sort: sort || "",
      }).toString();
      return `${url}?${searchParams}`;
    },
    fetcher,
    { keepPreviousData: true },
  );

  const initialGridState = useMemo(() => {
    const initialState: GridInitialStatePro = { pagination: { pageSize: params.size, page: params.page - 1 } };
    const sortModel = deserializeElasticSortString(params.sort);
    const filterModel = deserializeElasticQueryString(props.columns, params.query);

    if (sortModel.length > 0) initialState.sorting = { sortModel };
    if (filterModel) initialState.filter = { filterModel };

    return initialState;
    // We only want this to run once, so we ignore the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rows = useMemo(() => (data ? data.hits.hits.map(({ Source }) => ({ ...Source })) : []), [data]);
  const NoRowsOverlay = error ? ServerErrorOverlay : GridNoRowsOverlay;
  const totalCount = useMemo(() => {
    if (data?.hits.total) {
      switch (data.hits.total.relation) {
        case "eq":
          return data.hits.total.value;
        case "gte":
          return data.hits.total.value + 1;
        default:
          return 0;
      }
    }
    return data?.hits.total.value || 0;
  }, [data]);

  return (
    <ComposedDataGrid
      disableSelectionOnClick={true}
      {...props}
      rows={rows}
      rowCount={totalCount}
      loading={isLoading}
      pagination={true}
      page={params.page - 1}
      paginationMode="server"
      onPageChange={(newPage) => {
        setParams((prev) => ({ ...prev, page: newPage + 1 }));
      }}
      onPageSizeChange={(newPageSize) => {
        setParams((prev) => ({ ...prev, size: newPageSize }));
      }}
      rowsPerPageOptions={[5, 25, 50, 100, 200]}
      filterMode="server"
      onFilterModelChange={(model) => {
        const queryString = filterModelToElasticQueryString(model);
        setParams((prev) => ({ ...prev, query: queryString, page: 1 }));
      }}
      sortingMode="server"
      onSortModelChange={(model) => {
        const sortString = serializeSortModel(model);
        setParams((prev) => ({ ...prev, sort: sortString, page: 1 }));
      }}
      components={{
        LoadingOverlay: LinearProgress,
        NoRowsOverlay,
        Toolbar: GridToolbar,
        ...props.components,
      }}
      componentsProps={{
        noRowsOverlay: { error },
        toolbar: {
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true },
        },
        pagination: { labelDisplayedRows: ElasticAwarePaginationLabel },
        ...props.componentsProps,
      }}
      initialState={{
        ...props.initialState,
        ...initialGridState,
      }}
      onBulkAction={() => {
        // Refresh the data after 2 seconds to give ES time to process the bulk action
        setTimeout(() => {
          mutate();
        }, 2000);
      }}
    />
  );
};
