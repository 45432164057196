import { Box, useTheme } from "@mui/material";

export default function SectionWrapper({ children }: React.PropsWithChildren<any>) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: 4,
        background: "background.paper",
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
        position: "relative",
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
          p: 2,
        },
      }}
    >
      {children}
    </Box>
  );
}
