import { DataGridPro, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { Box, styled, Paper, Button, Typography, Divider, Grid } from "@mui/material";
import { FileUpload as FileUploadIcon, Download as DownloadIcon } from "@mui/icons-material";
import useInventoryUploads from "hooks/useInventoryUploads";
import { init } from "filestack-js";
import { v4 as uuidv4 } from "uuid";
import { api } from "../../services/api.service";
import { readableDate } from "utils/readableDate";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import { useInterval } from "hooks/useInterval";
import { GridCellExpand } from "components/GridCellExpand";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  height: "80vh",
  backgroundColor: "background.paper",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.mode == "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
  },
}));

export default function InventoryUploadsDataGrid() {
  const defaultPageSize = 25;
  const { createInventoryUpload, getInventoryUploads, inventoryUploads, totalPages } = useInventoryUploads();

  useInterval(async () => {
    await getInventoryUploads();
  }, 5000);

  const columns: GridColDef[] = [
    {
      field: "created_at",
      headerName: "Created",
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return readableDate(params.row.createdAt);
      },
    },
    {
      field: "user.email",
      headerName: "Created By",
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.user.email,
    },
    {
      field: "total_rows",
      headerName: "Rows",
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.totalRows;
      },
    },
    {
      field: "state",
      headerName: "Status",
      width: 150,
    },
    {
      field: "file_errors",
      headerName: "Errors",
      width: 500,
      sortable: false,
      renderCell: renderCellExpand,
    },
  ];

  function renderCellExpand(params: GridRenderCellParams<string>) {
    return <GridCellExpand value={params.row.fileErrors || ""} width={params.colDef.computedWidth} />;
  }

  const handleClick = (e: any) => {
    const client = init(process.env.REACT_APP_FILESTACK_API_KEY || "");
    const filename = uuidv4();

    const setCredentials = () => {
      api.post("/filestack_credential").then((resp) => {
        client.setSecurity(resp.data);
      });
    };

    setCredentials();

    const credentials_interval = setInterval(() => {
      setCredentials();
    }, 100000);

    const handleFileUploadFinish = () => {
      createInventoryUpload(
        `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/inventory_uploads/${filename}.csv`,
      );
    };

    const defaultOptions = {
      accept: [".csv"],
      maxFiles: 1,
      fromSources: ["local_file_system"],
      storeTo: {
        path: `/inventory_uploads/${filename}.csv`,
        container: process.env.REACT_APP_S3_BUCKET,
        region: process.env.REACT_APP_S3_REGION,
      },
      onClose: () => clearInterval(credentials_interval),
      onFileUploadFinished: handleFileUploadFinish,
    };

    client.picker(defaultOptions).open();
  };

  const pageState = useServerSideDataGridParams(inventoryUploads);

  return (
    <Box sx={{ width: "100%", height: "80vh" }}>
      <Paper elevation={2} sx={{ padding: "10px" }}>
        <Typography variant="h6" sx={{ my: 1, mx: 1 }}>
          Inventory Uploads
        </Typography>
        <Divider />
        <Grid container sx={{ padding: "20px 0" }} spacing={2} direction="row">
          <Grid item>
            <a
              href={process.env.PUBLIC_URL + "/inventory_upload_template.csv"}
              download
              style={{ textDecoration: "none", color: "#202020" }}
            >
              <Button variant="contained" component="label" color="inherit">
                <DownloadIcon />
                Download CSV Template
              </Button>
            </a>
          </Grid>

          <Grid item>
            <Button variant="contained" component="label" color="primary" onClick={handleClick}>
              {" "}
              <FileUploadIcon /> Upload CSV Template
            </Button>
          </Grid>
        </Grid>

        <StyledDataGrid
          disableSelectionOnClick
          rows={inventoryUploads}
          columns={columns}
          {...pageState}
          pageSize={pageState.pageSize || defaultPageSize}
          rowsPerPageOptions={[25, 50, 100]}
          rowCount={totalPages * (pageState.pageSize || defaultPageSize)}
          disableColumnFilter
        />
      </Paper>
    </Box>
  );
}
