import * as React from "react";
import { JSXElementConstructor, ReactElement, ReactNode, useRef, useState } from "react";
import {
  GridRowId,
  GridRowModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
  useGridApiContext,
  useGridApiEventHandler,
} from "@mui/x-data-grid-pro";
import { DataGridProProps } from "@mui/x-data-grid-pro/models/dataGridProProps";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { Checklist } from "@mui/icons-material";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { useEventListener } from "hooks/useEventListener";

export interface BulkActionsProps {
  getBulkActions?: () => ReactElement<GridActionsCellItemProps>[];
  onBulkAction?: () => void;
}

interface BulkActionsDataGridToolbarProps extends GridToolbarProps {
  getBulkActions?: () => ReactElement<GridActionsCellItemProps>[];
  onBulkAction?: () => void;
}

interface GridBulkActionsItemProps {
  icon?: ReactNode;
  label: string;
  onClick?: (selectedRows: Map<GridRowId, GridRowModel>, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

export function GridBulkActionsItem(props: GridBulkActionsItemProps) {
  const apiRef = useGridApiContext();

  return (
    <MenuItem
      onClick={(event) => {
        props.onClick?.(apiRef.current.getSelectedRows(), event);
      }}
    >
      {props.icon && <ListItemIcon sx={{ "& > .MuiSvgIcon-root": { fontSize: 20 } }}>{props.icon}</ListItemIcon>}
      <ListItemText>{props.label}</ListItemText>
    </MenuItem>
  );
}

export const dispatchBulkActionComplete = () => {
  const event = new CustomEvent("bulkActionComplete");
  window.dispatchEvent(event);
};

const Toolbar = (props: BulkActionsDataGridToolbarProps) => {
  const [enableBulkActions, setEnableBulkActions] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const apiRef = useGridApiContext();
  useGridApiEventHandler(apiRef, "selectionChange", (selectionModel) => {
    setEnableBulkActions(selectionModel.length > 0);
  });
  useEventListener("bulkActionComplete", () => {
    apiRef.current.setSelectionModel([]);
    props.onBulkAction?.();
  });

  return (
    <GridToolbarContainer sx={props.sx}>
      <Button
        size={"small"}
        ref={anchorRef}
        disabled={!enableBulkActions}
        startIcon={<Checklist />}
        onClick={() => {
          setOpen((prevOpen) => !prevOpen);
        }}
      >
        Bulk Actions
      </Button>
      <Menu anchorEl={anchorRef.current} open={open} onClose={() => setOpen(false)}>
        <MenuList sx={{ p: 0 }} onClick={() => setOpen(false)}>
          {props.getBulkActions?.() ?? "No bulk actions specified"}
        </MenuList>
      </Menu>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport {...props} />
    </GridToolbarContainer>
  );
};

export function withBulkActions<T extends DataGridProProps>(DataGridComponent: JSXElementConstructor<T>) {
  function BulkActionsDataGridPro({ getBulkActions, onBulkAction, ...props }: BulkActionsProps & T) {
    if (!props.checkboxSelection) return <DataGridComponent {...(props as T)} />;

    return (
      <DataGridComponent
        {...(props as T)}
        components={{ ...props.components, Toolbar }}
        componentsProps={{
          ...props.componentsProps,
          toolbar: {
            ...props.componentsProps?.toolbar,
            getBulkActions,
            onBulkAction,
          },
        }}
      />
    );
  }

  return BulkActionsDataGridPro;
}
