import { Box, Button, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BenefitingOrganizationTable from "./BenefitingOrganizationsTable";

export default function BenefitingOrganizations() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        padding: 6,
        minHeight: "calc(100vh - 64px)",
        lineHeight: 2,
      }}
    >
      <Typography variant="h4" fontWeight="bold">
        Benefiting Organizations
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography>
          View and manage all the benefitting organizations Revivn works with. Approve or decline benefiting
          organization after the review process.
        </Typography>
        <Button size="small" variant="contained" onClick={() => navigate("/benefiting-organization/new")}>
          ADD ORGANIZATION
        </Button>
      </Box>
      <Box component={Paper} sx={{ border: "1px solid", borderColor: "divider", mt: 3 }}>
        <BenefitingOrganizationTable />
      </Box>
    </Box>
  );
}
