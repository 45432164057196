import { DarkMode, LightMode } from "@mui/icons-material";
import { IconButton, IconButtonProps, ThemeOptions, useTheme } from "@mui/material";
import { ColorModeContext } from "App";
import { useContext } from "react";

export const ThemeSwitchButton = (props: IconButtonProps) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const icon = theme.palette.mode === "dark" ? <DarkMode /> : <LightMode />;

  return (
    <IconButton
      {...props}
      onClick={() => {
        colorMode.toggleColorMode();
      }}
    >
      {icon}
    </IconButton>
  );
};

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    text: {
      primary: "#263238",
      secondary: "#666666",
    },
    primary: {
      main: "#0089BD",
      light: "#DDF3FC",
    },
    secondary: {
      main: "#F9A62B",
      dark: "#D58031",
    },
    neutral: {
      main: "#64748B",
    },
    error: {
      main: "#c62828",
    },
  },
  typography: {
    h6: {
      color: "#0089BD",
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        button: {
          "&:hover": {
            background: "#DDF3FC",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#C62828",
        },
        colorSecondary: {
          "&$checked": {
            color: "#C62828",
          },
        },
        track: {
          backgroundColor: "#C62828",
          "$checked$checked + &": {
            backgroundColor: "#C62828",
          },
        },
      },
    },
  },
};

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    text: {
      primary: "#D9CDC7",
      secondary: "#666666",
    },
    primary: {
      main: "#0089BD",
      light: "#DDF3FC",
    },
    secondary: {
      main: "#F9A62B",
      dark: "#D58031",
    },
    neutral: {
      main: "#64748B",
    },
    error: {
      main: "#C62828",
    },
  },
  typography: {
    h6: {
      color: "#0089BD",
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        button: {
          "&:hover": {
            background: "#08455E",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#C62828",
        },
        colorSecondary: {
          "&$checked": {
            color: "#C62828",
          },
        },
        track: {
          backgroundColor: "#C62828",
          "$checked$checked + &": {
            backgroundColor: "#C62828",
          },
        },
      },
    },
  },
};

export const getThemeOptions = (mode: "light" | "dark"): ThemeOptions =>
  mode === "light" ? lightThemeOptions : darkThemeOptions;
