import { useMemo } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import usePickups from "hooks/usePickups";
import { Pickup } from "types/pickup";
import { styled, Paper, Box, TextField } from "@mui/material";
import { debounce } from "lodash";
import useServerSideDataGridParams from "../../hooks/useServerSideDataGridParams";
import { readableDate } from "utils/readableDate";
import { defaultInventoryFilters } from "constants/default_inventory_filters";
import PickupStateChip from "components/PickupState";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  height: "80vh",
  backgroundColor: "background.paper",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.mode == "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
  },
}));

export default function PickupsDataGrid() {
  const { pickups, count } = usePickups();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "Pickup",
        width: 125,
        renderCell: (params: GridRenderCellParams<Pickup>) => {
          return <Link to={`/pickups/${params.row.uuid}${defaultInventoryFilters}`}>{params.row.id}</Link>;
        },
      },
      {
        field: "organizations.name",
        headerName: "Company",
        valueGetter: (params: GridValueGetterParams) => params.row.organization.name,
        width: 200,
      },
      {
        field: "scheduledAt",
        headerName: "Date",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => {
          return readableDate(params.row.scheduledAt);
        },
      },
      {
        field: "itad",
        headerName: "ITAD",
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.itad?.name,
      },
      {
        field: "state",
        headerName: "State",
        width: 200,
        renderCell: (params: GridRenderCellParams<Pickup>) => {
          return <PickupStateChip pickupState={params.row.state} />;
        },
      },
      {
        field: "appleLaptopCount",
        headerName: "Mac Laptops",
        width: 150,
        sortable: false,
      },
      {
        field: "pcLaptopCount",
        headerName: "PC Laptops",
        width: 150,
        sortable: false,
      },
      {
        field: "address",
        headerName: "Address",
        width: 500,
        sortable: false,
        renderCell: (params: GridRenderCellParams<Pickup>) => {
          return (
            <Box>
              {params.row.location &&
                `${params.row.location.address || ""} ${params.row.location.address2 || ""} ${
                  params.row.location.city || ""
                } ${params.row.location.zipcode || ""} ${params.row.location.state || ""}`}
            </Box>
          );
        },
      },
      {
        field: "logisticsClickupLink",
        headerName: "Clickup",
        sortable: false,
        width: 150,
        renderCell: (params: GridRenderCellParams<Pickup>) => {
          return (
            params.row.logisticsClickupLink && (
              <a target="_blank" href={params.row.logisticsClickupLink}>
                {params.row.logisticsClickupLink.replace("https://app.clickup.com/t/", "")}
              </a>
            )
          );
        },
      },
    ],
    [],
  );
  const pageState = useServerSideDataGridParams(pickups);
  const { setSearchQuery } = pageState;

  return (
    <Box sx={{ width: "100%", mx: 0 }}>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Box sx={{ padding: "10px 0" }}>
          <TextField fullWidth onChange={debounce((e) => setSearchQuery(e.target.value), 300)} placeholder="Search" />
        </Box>
        <StyledDataGrid
          disableSelectionOnClick
          rows={pickups}
          columns={columns}
          {...pageState}
          pageSize={pageState.pageSize || 25}
          rowsPerPageOptions={[5, 10, 25]}
          rowCount={count}
          disableColumnFilter
        />
      </Paper>
    </Box>
  );
}
