import { Box, Button, Typography } from "@mui/material";
import LoginCoverImg from "images/phoenix.gif";
import { VpnKey } from "@mui/icons-material";

export default function LoginSso() {
  return (
    <Box sx={{ width: "100%", minHeight: "100vh", display: "grid", placeItems: "center", background: "black" }}>
      <Box sx={{}}>
        <img
          src={LoginCoverImg}
          alt="login cover"
          style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
        ></img>

        <Typography
          variant="h4"
          sx={{ textAlign: "center", mt: 3, fontWeight: "bold", color: "white", fontFamily: "monospace" }}
        >
          Phoenix
        </Typography>

        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "monospace",
            color: "white",
            mt: 3,
          }}
        >
          <Box>Providing the world access to a computer.</Box>
          <Box>Ensuring hardware reaches its full lifecycle.</Box>
          <Box>Making enterprise hardware lifecycle management easier.</Box>
        </Typography>

        <Box sx={{ textAlign: "center" }} mt={3}>
          <Button
            variant="outlined"
            size="large"
            startIcon={<VpnKey />}
            target="_blank"
            component={"a"}
            href={`${process.env.REACT_APP_SSO_URL}`}
          >
            {`Login with Okta`}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
