import { singleSelectColDef } from "components/DataGrid/columnDefs/singleSelectColDef";
import { GridColTypeDef, ValueOptions } from "@mui/x-data-grid-pro";

const warehouseValueOpts: ValueOptions[] = [
  { value: "New York", label: "NY" },
  { value: "San Francisco", label: "SF" },
  { value: "3PL", label: "3PL" },
];
export const warehouseColDef: GridColTypeDef = {
  headerName: "WH",
  width: 75,
  ...singleSelectColDef(warehouseValueOpts),
};
