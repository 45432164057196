import { useEventListener } from "hooks/useEventListener";
import { IndexedInventory } from "types/inventory";
import { dispatchBulkActionComplete } from "components/DataGrid/behaviors/withBulkActions";
import React, { useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";

export const BulkPrintAction = () => {
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<IndexedInventory[]>([]);

  useEventListener("bulkPrint", (event) => {
    if (event instanceof CustomEvent) {
      const details = event.detail as { selectedRows: IndexedInventory[] };
      setOpen(true);
      setSelectedRows(details.selectedRows);
    }
  });

  const invUpdates = useMemo(() => {
    return selectedRows.map((inv) => ({
      id: inv.id,
      printedAt: new Date().toUTCString(),
      label: `${inv.pickupId} - ${inv.lineId}`,
    }));
  }, [selectedRows]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Mark inventory as printed?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          By clicking yes, we will mark these inventory labels as printed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>No</Button>
        <RevivnApiForm
          initialValues={{ inventories: invUpdates }}
          action={"/inventories/bulk/edit"}
          method={"PUT"}
          onSuccess={() => {
            dispatchBulkActionComplete();
            setOpen(false);
          }}
        >
          <SubmitAwareButton type="submit">Yes</SubmitAwareButton>
        </RevivnApiForm>
      </DialogActions>
    </Dialog>
  );
};
