import { TextField } from "@mui/material";
import { useField } from "formik";

export const LabeledField = ({ label, ...props }: any) => {
  const [inputProps, metaProps] = useField(props);
  const { error, touched } = metaProps;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label style={{ fontWeight: 600, fontSize: 15 }}>{label}</label>
      <TextField
        {...inputProps}
        size="small"
        error={touched && !!error}
        helperText={touched && error ? error : ""}
        disabled={props.disabled}
      />
    </div>
  );
};
