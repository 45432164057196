import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import StockImagesUploader from "pages/StockImagesUploader";
import PickupsPage from "pages/PickupsPage";
import AssetRetrievals from "pages/AssetRetrievalPage";
import InventoriesPage from "pages/InventoriesPage";
import EnrolledInventoriesPage from "pages/EnrolledInventoriesPage";
import InventoryTemplatesPage from "pages/InventoryTemplatesPage";
import TechnicianInventoryPage from "pages/TechnicianInventoryPage";
import ScanPage from "pages/ScanPage";
import ReceivingPage from "pages/ReceivingPage";
import OutboundPage from "pages/OutboundPage";
import { FulfillmentPage } from "pages/FulfillmentPage";
import { BatchesPage } from "pages/BatchesPage";
import { PicklistLayout } from "pages/Layouts/PicklistLayout";
import { PicklistNewPage } from "pages/Picklist/PicklistNewPage";
import { PicklistCurrentBatchPage } from "pages/Picklist/PicklistCurrentBatchPage";
import DriversPage from "pages/DriversPage";
import ScanAndDiscover from "pages/ScanAndDiscover";
import VirtualPickup from "pages/VirtualPickup";
import InventoryUploadsPage from "pages/InventoryUploadsPage";
import InventoryPricerUploadsPage from "pages/InventoryPricerUploadsPage";
import ListingsPage from "pages/ListingsPage";
import ProductCatalogsPage from "pages/ProductCatalogsPage";
import { ScanInventoryPage } from "pages/PhotoBooth/ScanInventoryPage";
import { ConfigurationPage } from "pages/PhotoBooth/ConfigurationPage";
import { preload } from "swr";
import { fetcher } from "services/api.service";
import LogisticsPage from "pages/LogisticsPage";
import FinancePage from "pages/FinancePage";
import BenefitingOrganizationsPage from "pages/BenefitingOrganizationsPage";
import DonationRequestsPage from "pages/DonationRequestsPage";
import SupportPage from "pages/SupportPage";
import React from "react";
import { userHasPermission } from "utils/userPermissions";
import useCurrentUser from "hooks/useCurrentUser";
import { CapturePhotosPage } from "pages/PhotoBooth/CapturePhotosPage";
import { ReviewPhotosPage } from "pages/PhotoBooth/ReviewPhotosPage";
import { PhotoBoothLayout } from "pages/Layouts/PhotoBoothLayout";
import { LoggedInLayout } from "pages/Layouts/LoggedInLayout";
import { AssetRetrievalEditPage } from "pages/AssetRetrievalEditPage";

const PermissionedRoute = ({ children, resource }: { children?: React.ReactNode; resource: string }) => {
  const { currentUserPermissions } = useCurrentUser();
  if (userHasPermission(resource, currentUserPermissions)) {
    return <>{children}</>;
  } else {
    return <> You do not have permission to view this page</>;
  }
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<LoggedInLayout />} errorElement={<LoggedInLayout />}>
      <Route index element={<Navigate replace={true} to="/pickups" />} />
      <Route path="/login" element={<Navigate replace={true} to={"/pickups"} />} />
      <Route path="/phoenix_login" element={<Navigate replace={true} to={"/pickups"} />} />
      <Route path="/stock-photos" element={<StockImagesUploader />} />
      <Route path="/pickups/*" element={<PickupsPage />} />
      <Route path="/asset_retrievals/*" element={<AssetRetrievals />} />
      <Route path="/asset_retrievals/:assetRetrievalUuid" element={<AssetRetrievalEditPage />} />
      <Route path="/inventories/*" element={<InventoriesPage />} />
      <Route path="/enrolled" element={<EnrolledInventoriesPage />} />
      <Route path="/inventory-templates" element={<InventoryTemplatesPage sx={{ mx: 10, my: 8 }} />} />
      <Route path="/inventory-templates/:templateUuid" element={<InventoryTemplatesPage sx={{ mx: 10, my: 8 }} />} />
      <Route path="/inventories/:inventoryId" element={<TechnicianInventoryPage />} />
      <Route path="/scan" element={<ScanPage />} />
      <Route path="/technician" element={<TechnicianInventoryPage />} />
      <Route path="/technician/:inventoryId" element={<TechnicianInventoryPage />}></Route>
      <Route path="/technician/:inventoryId/inventory-parts/:type" element={<TechnicianInventoryPage />} />
      <Route path="/receiving/*" element={<ReceivingPage />}></Route>
      <Route path="/outbound" element={<OutboundPage />}></Route>
      <Route path="/fulfillment" element={<FulfillmentPage />} />
      <Route path="/batches" element={<BatchesPage />} />
      <Route path="/picklist" element={<PicklistLayout />}>
        <Route index element={<Navigate replace={true} to="new" />} />
        <Route path="new" element={<PicklistNewPage />} />
        <Route path="current-batch" element={<PicklistCurrentBatchPage />} />
      </Route>
      <Route path="/drivers" element={<DriversPage />}></Route>
      <Route path="/scan-and-discover" element={<ScanAndDiscover />}></Route>
      <Route path="/virtual-pickup" element={<VirtualPickup />}></Route>
      <Route path="/inventory-uploads" element={<InventoryUploadsPage />}></Route>
      <Route path="/inventory-pricer-uploads" element={<InventoryPricerUploadsPage />}></Route>
      <Route path="/listings/*" element={<ListingsPage />} />
      <Route path="/product_catalogs/*" element={<ProductCatalogsPage />} />
      <Route path="/resale-photo-booth" element={<PhotoBoothLayout />}>
        <Route index element={<ScanInventoryPage />} />
        <Route path="configuration" element={<ConfigurationPage />} />
        <Route
          path=":inventoryId"
          errorElement={<ScanInventoryPage />}
          loader={async ({ params }) => {
            return preload(`/inventories/${params.inventoryId}`, fetcher);
          }}
        >
          <Route index element={<Navigate replace={true} to="capture/1" />} />
          <Route path="capture/:photoSet" element={<CapturePhotosPage />} />
          <Route path="review" element={<ReviewPhotosPage />} />
        </Route>
      </Route>

      <Route
        path="/logistics/*"
        element={
          <PermissionedRoute resource={"logistics"}>
            <LogisticsPage />
          </PermissionedRoute>
        }
      />
      <Route
        path="/finance/*"
        element={
          <PermissionedRoute resource={"finance"}>
            <FinancePage />
          </PermissionedRoute>
        }
      />
      <Route
        path="/benefiting-organization/*"
        element={
          <PermissionedRoute resource={"tech_access"}>
            <BenefitingOrganizationsPage />
          </PermissionedRoute>
        }
      ></Route>
      <Route
        path="/donation-requests/*"
        element={
          <PermissionedRoute resource={"tech_access"}>
            <DonationRequestsPage />
          </PermissionedRoute>
        }
      ></Route>
      <Route
        path="/support/*"
        element={
          <PermissionedRoute resource={"support"}>
            <SupportPage />
          </PermissionedRoute>
        }
      ></Route>
    </Route>,
  ),
);
export default function LoggedInRouter() {
  return <RouterProvider router={router} />;
}
