import {
  TableContainer,
  Box,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  debounce,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import Breadcrumbs from "components/common/Breadcrumbs";
import { useQuery } from "hooks/useQuery";
import FinancePickupRow from "./FinancePickupRow";
import useFinancePickups from "hooks/useFinancePickups";

export default function FinancePickups() {
  const query = useQuery();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const { pickups, totalPages, updatePickup } = useFinancePickups();
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    query.delete("page[number]");
    query.delete("q");
    query.append("q", searchQuery);
    if (value != 1) {
      query.append("page[number]", String(value));
    }

    navigate(`?${query.toString()}`);
  };

  const handleSearchChange = (searchTerm: string) => {
    setSearchQuery(searchTerm);
    query.delete("page[number]");
    query.delete("q");
    if (searchTerm) {
      query.append("q", searchTerm);
    }
    if (page != 1) {
      query.append("page[number]", String(page));
    }

    navigate(`?${query.toString()}`);
  };

  return (
    <Box>
      <Breadcrumbs data={[{ label: "Finance", to: "/finance" }, { label: "Pickups" }]}></Breadcrumbs>
      <Box mt={4}>
        <Typography variant="h4">Pickups</Typography>
        <Typography mt={0.5}>Assign buyback for pickups</Typography>
      </Box>
      <Box>
        <Grid container spacing={2} sx={{ padding: "5px" }}>
          <Grid item xs={6}>
            <TextField
              id="search"
              sx={{ backgroundColor: "background.paper" }}
              autoComplete="off"
              fullWidth
              onChange={debounce((e) => handleSearchChange(e.target.value), 300)}
              placeholder="Search company or location"
            />
          </Grid>
        </Grid>
      </Box>

      <TableContainer
        sx={{
          mt: 6,
          border: "none",
          background: "background.paper",
          borderRadius: "4px",
        }}
        component={Box}
      >
        <Table sx={{ minWidth: 850 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 200 }}>PICKUP DATE</TableCell>
              <TableCell sx={{ width: 200 }}>INTERNAL PICKUP ID</TableCell>
              <TableCell sx={{ width: 200 }}>ACCOUNT MANAGER</TableCell>
              <TableCell sx={{ width: 200 }}>LOT VALUE</TableCell>
              <TableCell>COMPANY</TableCell>
              <TableCell>LOCATION</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pickups.map((pickup) => (
              <FinancePickupRow key={pickup.id} pickup={pickup} updatePickup={updatePickup} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <Box sx={{ padding: "20px 0" }}>
          <Pagination count={totalPages} page={page} onChange={handlePageChange} />
        </Box>
      )}
    </Box>
  );
}
