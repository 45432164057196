import {
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { readableDate } from "utils/readableDate";
import { Discount as DiscountIcon, Edit } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

function TransactionGroupTable(transactions: any) {
  const { uuid } = useParams();
  const navigate = useNavigate();
  return (
    <div>
      <TableContainer
        sx={{
          mt: 2,
        }}
      >
        <Table sx={{ ".MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th": { border: 0 } }}>
          <TableHead sx={{ ".MuiTableCell-root": { color: "text.disabled", borderBottom: 0 } }}>
            <TableRow>
              <TableCell>DATE POSTED</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>DESCRIPTION</TableCell>
              <TableCell>AMOUNT</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions &&
              transactions.transactions.map((transaction: any) => {
                return (
                  <>
                    <TableRow key={transaction.id}>
                      <TableCell>{readableDate(transaction.postedAt)}</TableCell>
                      <TableCell>
                        <Typography variant="body2">{transaction.type}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{transaction.description}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">${transaction.displayAmountInCents / 100.0}</Typography>
                      </TableCell>
                      <TableCell>
                        {transaction.waived && (
                          <Chip
                            style={{ color: "white", fontWeight: 600, marginLeft: "10px", fontSize: "12px" }}
                            color="secondary"
                            label="FEES WAIVED"
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          disabled={transaction.description?.includes("Asset Retrieval Credit")}
                          onClick={() => {
                            navigate(
                              `/finance/clients/${uuid}/transaction_groups/${transaction.type.split(" ").join("")}/${
                                transaction.id
                              }`,
                            );
                          }}
                        >
                          <Edit />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TransactionGroupTable;
