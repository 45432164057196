import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { Box, Typography, IconButton, MenuItem, Alert, Grid, SelectChangeEvent } from "@mui/material";
import { AppSelect } from "components/common/Input";
import { pickupTimeSlots } from "constants/timeSlots";
import { startOfMonth, startOfWeek, addMonths, isSunday, isSaturday, format, isSameMonth, addDays } from "date-fns";
import { LogisticsPickupContext } from "hooks/useLogisticsPickup";

import { useState, useMemo, useEffect, useContext, Dispatch, SetStateAction } from "react";

export default function PickupDate() {
  const [isSaved, setIsSaved] = useState<boolean>(false);
  return (
    <Box>
      <Grid container justifyContent="space-between" direction="row" alignItems="center" sx={{ p: "10px", pr: "10px" }}>
        <Typography variant="h6" fontSize="18px" fontWeight="bold">
          8. Select the day and time for the pickup
        </Typography>
        {isSaved ? <Alert>Saved</Alert> : null}
      </Grid>

      <Typography mt={0.5} variant="body2">
        Clients will see this pickup as "scheduled" in their portal when you create this pickup
      </Typography>

      <Box mt={3}>
        <Calendar setIsSaved={setIsSaved} />
      </Box>
    </Box>
  );
}

interface CalendarDay {
  date: Date;
  day: string;
  week: string;
  type: "out" | "used" | "free";
}

interface SavedProps {
  setIsSaved: Dispatch<SetStateAction<boolean>>;
}

function Calendar({ setIsSaved }: SavedProps) {
  const { pickup, updatePickup } = useContext(LogisticsPickupContext);
  let [month, setMonth] = useState(() => {
    return startOfMonth(new Date());
  });

  let [time, setTime] = useState<{ date: Date; time?: string } | null>(null);

  useEffect(() => {
    if (pickup.scheduledAt) {
      const utc = new Date(pickup.scheduledAt).toLocaleString("en-US", { timeZone: "UTC" });

      setTime({
        date: new Date(utc),
      });

      setMonth(startOfMonth(new Date(pickup.scheduledAt)));
    }
  }, [pickup.scheduledAt]);

  const handleTimeChange = (e: SelectChangeEvent<string>) => {
    updatePickup({ ...pickup, confirmedTime: e.target.value }).then(() => {
      setIsSaved(true);
    });
  };

  let monthDays = useMemo(() => {
    let firstDayOfTheWeek = startOfWeek(month);
    let nextMonth = addMonths(month, 1);

    let days: CalendarDay[] = [];
    let p = firstDayOfTheWeek;
    do {
      if (isSunday(p) || isSaturday(p)) {
        continue;
      }

      let day = format(p, "d");
      let week = format(p, "EEEEEE");

      days.push({
        date: p,
        day,
        week,
        type: isSameMonth(p, month) ? "free" : "out",
      });
    } while ((p = addDays(p, 1)) && (isSameMonth(p, nextMonth) ? days.length % 5 !== 0 : true));

    // check first row if empty we should delete it
    let isFirstRowShouldBeRemoved = days[4].type === "out";
    if (isFirstRowShouldBeRemoved) {
      days.splice(0, 5);
    }

    return days;
  }, [month]);

  function nextMonth() {
    setMonth(startOfMonth(addMonths(month, 1)));
  }

  function prevMonth() {
    setMonth(startOfMonth(addMonths(month, -1)));
  }

  const formattedDate = (date?: Date) => {
    if (!date) return;
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  return (
    <>
      <Box>
        {/* header */}
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Typography fontSize={"20px"} fontWeight="600" width="170px">
            {format(month, "MMMM yyyy")}
          </Typography>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <IconButton size="small" sx={{ svg: { fontSize: "18px" } }} onClick={prevMonth}>
              <ArrowBackIosNew />
            </IconButton>
            <IconButton size="small" sx={{ svg: { fontSize: "18px" } }} onClick={nextMonth}>
              <ArrowForwardIos />
            </IconButton>
          </Box>
        </Box>

        {/* body */}
        <Box mt={1} px={{ xs: 0, md: 2 }}>
          <Box sx={{ mb: 2, display: "flex", gap: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
            {["M", "T", "W", "TH", "F"].map((day) => (
              <Box key={day} sx={{ flex: 1, py: 2, fontSize: "20px", color: "text.secondary", textAlign: "center" }}>
                {day}
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gap: 1 }}>
            {monthDays.map((item) => {
              let isUsed = item.type === "used";
              let isFree = item.type === "free";
              let isSelected = formattedDate(time?.date) === formattedDate(item.date);

              return (
                <Box
                  key={item.date.toISOString()}
                  disabled={isUsed}
                  component="button"
                  onClick={() => {
                    if (isFree) {
                      setTime(isSelected ? null : { date: item.date });

                      const dateString = `${item.date.getFullYear()}-${
                        item.date && item.date.getMonth() + 1
                      }-${item.date.getDate()}`;

                      updatePickup({ ...pickup, scheduledAt: dateString }).then(() => {
                        setIsSaved(true);
                      });
                    }
                  }}
                  sx={[
                    {
                      cursor: "pointer",
                      background: "white",
                      py: 2.5,
                      fontSize: "20px",
                      fontFamily: "Catamaran",
                      textAlign: "center",
                      borderRadius: "4px",
                      border: `1px solid white`,
                    },
                    isUsed && { cursor: "not-allowed", background: "rgba(0, 0, 0, 0.08);" },
                    isFree && { border: (theme) => `1px solid ${theme.palette.primary.main}` },
                    isSelected && { backgroundColor: "primary.main", color: "white" },
                  ]}
                >
                  {item.day}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box mt={5}>
        <Typography fontSize={"20px"} fontWeight="600">
          Select Time:
        </Typography>

        <Typography fontSize={"16px"} fontWeight="400">
          Customer Requested Time: {pickup.requestedTime}
        </Typography>
        <Typography fontSize={"16px"} fontWeight="400">
          Customer Comments: {pickup.comments}
        </Typography>

        <Box mt={3} sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: 3 }}>
          <AppSelect defaultValue={pickup.confirmedTime || ""} onChange={(e) => handleTimeChange(e)}>
            {pickupTimeSlots.map((timeSlot: string) => {
              return (
                <MenuItem key={timeSlot} value={timeSlot}>
                  {timeSlot}
                </MenuItem>
              );
            })}
          </AppSelect>
        </Box>
      </Box>
    </>
  );
}
