import { Button, Box, Typography } from "@mui/material";
import { Check, Edit, Cancel } from "@mui/icons-material";

import { Pickup } from "types/pickup";
import {
  assets,
  contact,
  InfoCell,
  internalNotes,
  pickupReason,
  submittedBy,
  customerComments,
  attachments,
  locationNotes,
  orderNumber,
  address,
} from "./shared";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Modal } from "components/common/Modal";
import { useState } from "react";
import PickupCancelModal from "../PickupCancelModal";

interface PendingConfirmationDrawerProps {
  pickup: Pickup;
  confirmPickup: (pickupUuid: string) => Promise<void>;
  cancelPickup: (pickupUuid: string, setCancellationReason: string) => Promise<void>;
}

export function PendingConfirmationDrawer({ pickup, confirmPickup, cancelPickup }: PendingConfirmationDrawerProps) {
  const [approveOpen, setApproveOpen] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const navigate = useNavigate();
  const handleConfirmPickup = (pickupUuid: string) => {
    confirmPickup(pickupUuid).then(() => {
      setApproveOpen(false);
      navigate(`/logistics/pickups/${pickupUuid}/edit`);
    });
  };

  return (
    <Box mb={5} mx={2}>
      <Box
        sx={{
          py: 3,
          px: 4,
          background: "#FAFAFA",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
          <InfoCell title="EXTERNAL PICKUP ID" items={orderNumber(pickup)} />
          <InfoCell title="INTERNAL PICKUP ID" items={[pickup.id]} />
          <InfoCell title="PICKUP REASON" items={pickupReason(pickup)} />
          <InfoCell title="SUBMITTED BY" items={submittedBy(pickup)} />
          <InfoCell title="POINT OF CONTACT" items={contact(pickup)} />
          <InfoCell title="ASSETS" items={assets(pickup)} />
          <InfoCell title="ATTACHMENTS" items={attachments(pickup)} />
          <InfoCell title="LOCATION" items={address(pickup)} />
          <InfoCell title="LOCATION NOTES" items={locationNotes(pickup)} />
          <InfoCell title="CUSTOMER COMMENTS" items={customerComments(pickup)} />
          <InfoCell title="PRE-PICKUP NOTES" items={internalNotes(pickup)} />
        </Box>
      </Box>

      <Box mt={2} sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
        <Button
          variant="contained"
          startIcon={<Check />}
          onClick={() => {
            setApproveOpen(true);
          }}
        >
          approve
        </Button>
        <Button
          variant="contained"
          startIcon={<Edit />}
          component={RouterLink}
          to={`/logistics/pickups/${pickup.uuid}/edit`}
        >
          edit details
        </Button>
        <Button variant="contained" startIcon={<Cancel />} onClick={() => setCancelModalOpen(true)}>
          cancel
        </Button>

        <ApproveModal
          open={approveOpen}
          setOpen={setApproveOpen}
          handleConfirmPickup={() => handleConfirmPickup(pickup.uuid)}
        />
        <PickupCancelModal
          open={isCancelModalOpen}
          setOpen={setCancelModalOpen}
          cancelPickup={cancelPickup}
          pickupUuid={pickup.uuid}
        />
      </Box>
    </Box>
  );
}

interface ApproveModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleConfirmPickup(): void;
}

export function ApproveModal({ open, setOpen, handleConfirmPickup }: ApproveModalProps) {
  return (
    <Modal open={open} setOpen={setOpen} width={"683px"}>
      <Box mt={4}>
        <Box sx={{ textAlign: "center" }}>
          <Check color="primary" sx={{ fontSize: "60px" }} />
          <Typography mt={1} variant="h5" component="h2" fontWeight={800} fontSize={"24px"}>
            Approve Pickup
          </Typography>
          <Typography mt={1} color="text.secondary" fontSize={"16px"}>
            Are you sure you want to proceed with approving this pickup? A confirmation email will be sent to the user
            as well.
          </Typography>

          <Box mt={4.5} sx={{ display: "flex", gap: 1, alignItems: "center", justifyContent: "center" }}>
            <Button variant="contained" onClick={() => handleConfirmPickup()}>
              Yes, approve
            </Button>
            <Button variant="contained" onClick={() => setOpen(false)}>
              no, go back
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
