import { Attachment, CloseOutlined, DriveFolderUpload } from "@mui/icons-material";
import { Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useDropzone, Accept } from "react-dropzone";

interface Document {
  name: string;
  url?: string;
  onDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface FileDropSectionProps {
  icon?: React.ReactNode;
  accept: Accept;
  acceptHint?: React.ReactNode;
  onUpload?: (files: File[]) => void;
  documents?: Document[];
}

function FileDropSection({
  icon = <DriveFolderUpload color="primary" sx={{ fontSize: "32px" }} />,
  accept,
  acceptHint,
  onUpload,
  documents,
}: FileDropSectionProps) {
  let [loading, setLoading] = useState(false);
  const theme = useTheme();

  const { getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept,
    onDrop: async (files) => {
      setLoading(true);
      try {
        await onUpload?.(files);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div>
      <Box
        sx={[
          !!documents && {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 2,
            [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
          },
        ]}
      >
        {documents && (
          <Box>
            <Typography fontSize={"12px"} fontWeight={800}>
              Attach Documents
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                maxWidth: "400px",
                textOverflow: "ellipsis",
              }}
            >
              {documents.map((document: Document) => {
                return (
                  <Tooltip
                    key={document.url}
                    title={
                      <React.Fragment>
                        <img src={document.url} alt={document.name} loading="lazy" width={280} />
                      </React.Fragment>
                    }
                  >
                    <Box
                      key={document.url}
                      component="a"
                      href={document.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        border: (theme) => `1px solid ${theme.palette.primary.main}`,
                        borderRadius: "4px",
                        p: 1,
                        color: "primary.main",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontFamily: "Catamaran",
                        textDecoration: "none",
                        position: "relative",
                      }}
                    >
                      <Attachment />
                      {document.name}
                      <Box
                        component="button"
                        sx={{
                          height: "24px",
                          width: "24px",
                          background: "primary.main",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: 0,
                          borderRadius: "100%",
                          position: "absolute",
                          top: "-4px",
                          right: "-8px",
                          cursor: "pointer",
                        }}
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.preventDefault();
                          document.onDelete(e);
                        }}
                      >
                        <CloseOutlined />
                      </Box>
                    </Box>
                  </Tooltip>
                );
              })}
            </Box>
          </Box>
        )}

        <Box>
          {acceptHint && (
            <Typography fontSize={"12px"} textAlign={{ xs: "left", md: "right" }}>
              <strong>Accepted formats:</strong> {acceptHint}
            </Typography>
          )}

          <Box
            {...getRootProps({
              sx: [
                {
                  height: "190px",
                  border: `1px dashed ${useTheme().palette.divider}`,
                  borderRadius: 1,
                  display: "grid",
                  placeItems: "center",
                },
                isFocused && { borderColor: "primary.main" },
                isDragAccept && { borderColor: "primary.main" },
                isDragReject && { borderColor: "primary.main" },
                loading && { opacity: 0.5 },
              ],
            })}
          >
            <Box sx={{ textAlign: "center" }}>
              <input {...getInputProps()} />

              {icon}

              <Typography color="text.secondary" fontWeight={"600"}>
                Drag and drop <br />
                or
              </Typography>
              <Button sx={{ mt: 0.5 }} variant="outlined" onClick={open}>
                BROWSE FILES
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default FileDropSection;
