import { GridColTypeDef } from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";

interface UrlFunctionParams<T> {
  value: unknown;
  row: T;
}

type UrlFunction<T> = (params: UrlFunctionParams<T>) => string;

export function linkColDef<T>(urlFn: UrlFunction<T>): GridColTypeDef {
  return {
    renderCell({ row, value }) {
      if (!value || !row) return null;
      return <Link to={urlFn({ value, row })}>{value}</Link>;
    },
  };
}
