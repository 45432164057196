import {
  DataGridPro,
  GridColDef,
  getGridStringOperators,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiRef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { Link, useParams } from "react-router-dom";
import { Box, styled, Paper, LinearProgress, Stack, Typography } from "@mui/material";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import { useState } from "react";
import { useAlert } from "hooks/useAlert";
import { GridStopCellEditModeParams } from "@mui/x-data-grid/models/api/gridEditingApi";
import useClients from "hooks/useClients";
import { Client } from "types/client";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  height: "80vh",
  border: "none",
  backgroundColor: "background.paper",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.mode == "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
  },
}));

export default function ClientsDataGrid() {
  const defaultPageSize = 25;
  const useClientsState = useClients(defaultPageSize);
  const { clients, count, isLoading } = useClientsState;
  const [alertChange, setAlertChange] = useState(false);
  const { alertSuccess, alertError } = useAlert();
  const [rowsToEdit, setRowsToEdit] = useState<{ id: number; ram?: string; serial_number?: string; sku?: string }[]>(
    [],
  );
  const [lastEditedCell, setLastEditedCell] = useState<GridStopCellEditModeParams>();
  const [isPickupAvailable, setIsPickupAvailable] = useState(false);
  const params = useParams();

  const apiRef = useGridApiRef();

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
      renderCell: (params: GridRenderCellParams<Client>) => {
        return <Link to={`/logistics/clients/${params.row.uuid}`}>{params.row.name}</Link>;
      },
    },
    {
      field: "accountRep",
      headerName: "Account Rep",
      width: 300,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<Client>) => {
        return (
          <Box>{params.row.accountRep && `${params.row.accountRep.firstName} ${params.row.accountRep.lastName}`}</Box>
        );
      },
    },
    {
      field: "salesRep",
      headerName: "Sales Rep",
      width: 300,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<Client>) => {
        return <Box>{params.row.salesRep && `${params.row.salesRep.firstName} ${params.row.salesRep.lastName}`}</Box>;
      },
    },
    {
      field: "tier",
      headerName: "Tier",
      width: 200,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "state",
      headerName: "Status",
      width: 200,
      filterable: false,
      renderCell: (params: GridRenderCellParams<Client>) => {
        return <Typography color={params.row.state == "inactive" ? "gray" : "green"}>{params.row.state}</Typography>;
      },
    },

    {
      field: "wonByRep",
      headerName: "Won By Rep",
      width: 300,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<Client>) => {
        return <Box>{params.row.wonByRep && `${params.row.wonByRep.firstName} ${params.row.wonByRep.lastName}`}</Box>;
      },
    },
  ];

  const pageState = useServerSideDataGridParams(clients);

  return (
    <Box sx={{ width: "100%", height: "80vh" }}>
      <Paper elevation={2} sx={{ mt: 2, p: 1 }}>
        <StyledDataGrid
          rows={clients}
          columns={columns}
          {...pageState}
          pageSize={pageState.pageSize || defaultPageSize}
          rowsPerPageOptions={[5, 10, 25, 100]}
          rowCount={count}
          filterMode="server"
          apiRef={apiRef}
          checkboxSelection={false}
          checkboxSelectionVisibleOnly={false}
          loading={isLoading}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: CustomToolbar,
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No clients matching your filters
              </Stack>
            ),
          }}
          componentsProps={{ filterPanel: { sx: { minHeight: "200px" } } }}
        />
      </Paper>
    </Box>
  );
}
