import { Box, Typography, Button, Divider, Stack, Link, LinearProgress } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { SearchInput } from "components/common/Input";
import useClients from "hooks/useClients";
import useLocations from "hooks/useLocations";

import { Link as RouterLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

export function LocationShow() {
  const { client, clientLoading } = useClients();
  const { currentLocation, locationLoading } = useLocations();

  if (clientLoading || locationLoading) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics?logistics_status=scheduled" },
          { label: "Clients", to: "/logistics/clients" },
          { label: client ? client?.name : "", to: `/logistics/clients/${client?.uuid}` },
          { label: currentLocation?.name },
        ]}
      ></Breadcrumbs>

      <Box mt={4}>
        <Typography variant="h4" fontWeight="bold">
          {currentLocation?.name}
        </Typography>
      </Box>

      <Box mt={4}>
        <Box
          sx={{
            p: 4,
            background: "background.paper",
            borderRadius: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <Box sx={{ flex: 1, minWidth: "200px" }}>
              <Typography fontWeight={"bold"}>Address</Typography>
              <Box mt={0.5}>
                <Typography fontSize="14px">
                  {" "}
                  {currentLocation?.address} {currentLocation?.address2}
                  <Box />
                  {currentLocation?.city}, {currentLocation?.state} {currentLocation?.zipcode}{" "}
                  {currentLocation?.country}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ flex: 1, minWidth: "200px" }}>
              <Typography fontWeight={"bold"}>Total End Users</Typography>
              <Box mt={0.5}>
                <Typography fontSize="14px"> {currentLocation?.approximatePeople}</Typography>
              </Box>
            </Box>
            {/* <Box sx={{ flex: 1, minWidth: "200px" }}>
              <Typography fontWeight={"bold"}>Auto-Generated COI:</Typography>
              <Box mt={0.5}>
                <Link
                  component="button"
                  mt={0.5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    cursor: "pointer",
                    fontSize: "16px",
                    fontFamily: "Catamaran",
                    textDecoration: "none",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  <Download color="primary" />
                  Company_Brooklyn_COI.pdf
                </Link>
              </Box>
            </Box>
            <Box sx={{ flex: 1, minWidth: "200px" }}>
              <Typography fontWeight={"bold"}>Pictures:</Typography>
              <Box mt={0.5}>
                {Array.from({ length: 3 }, (_, i) => (
                  <Box
                    component="a"
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: theme.palette.primary.main,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontFamily: "Catamaran",
                    }}
                  >
                    <Attachment />
                    name {i + 1}
                  </Box>
                ))}
              </Box>
            </Box> */}
          </Box>

          <Divider sx={{ my: 4 }} />

          <Box>
            <Typography fontWeight={"bold"}>Access Instructions:</Typography>
            <Typography mt={0.5}>{currentLocation?.accessInstructions}</Typography>
          </Box>
          <Box mt={2}>
            <Typography fontWeight={"bold"}>Internal Revivn Location Notes:</Typography>
            <Typography mt={0.5}>{currentLocation?.internalNotes}</Typography>
          </Box>
        </Box>
      </Box>

      <Box mt={2} sx={{ display: "flex", gap: 10 }}>
        {location &&
          currentLocation?.locationContacts &&
          currentLocation?.locationContacts.map((contact) => {
            return (
              <Box
                key={contact.uuid}
                sx={{
                  px: 4,
                  py: 2,
                  background: "background.paper",
                  borderRadius: 1,
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  minHeight: "150px",
                  minWidth: "250px",
                }}
              >
                <Box sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
                  <Typography
                    variant="body2"
                    textTransform="uppercase"
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    {`${contact.firstName} ${contact.lastName}`}
                  </Typography>
                  <Box>{`${contact?.email}`}</Box>
                  <Box>{`${contact?.phoneNumber}`}</Box>

                  <Stack mt={2} direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                    <Link sx={{ textDecoration: "none" }} component={RouterLink} to={`contacts/${contact?.uuid}/edit`}>
                      Edit
                    </Link>
                  </Stack>
                </Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}
