import { useState } from "react";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useAlert } from "hooks/useAlert";
import { Button, IconButton, Popover, Stack, TextField, Tooltip } from "@mui/material";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { Field } from "formik";
import { FulfillmentBatch } from "hooks/useCurrentFulfillmentBatch";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

export function BatchReassignUserRowAction({ row }: { row: FulfillmentBatch }) {
  const [error, setError] = useState<null | string>(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const formId = `reassign_user${row.id}`;
  const apiRef = useGridApiContext();
  const { alertSuccess } = useAlert();

  return (
    <>
      <Tooltip title="Reassign User" disableInteractive>
        <IconButton
          color="primary"
          onClick={(event) => {
            setAnchorEl(event.currentTarget.parentElement as HTMLButtonElement);
            setOpen(true);
          }}
        >
          <ManageAccountsIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setOpen(false);
          setError(null);
        }}
        sx={{ padding: 4 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <RevivnApiForm
          FormProps={{ id: formId }}
          initialValues={{ userEmail: null }}
          action={`fulfillment_batches/${row.id}`}
          method="PUT"
          onSubmit={() => {
            setError(null);
          }}
          onSuccess={(response) => {
            setOpen(false);
            const updatedItem = response.data as FulfillmentBatch;
            apiRef.current?.updateRows([{ ...updatedItem }]);
            alertSuccess("Successfully reassigned the batch!");
          }}
          onError={(err) =>
            setError(err.response?.data.errors.user_email[0] || setError(err.response?.data?.error?.message))
          }
        >
          <Stack sx={{ p: 2, gap: 2 }} alignItems={"center"} direction="row">
            <Field
              label="User Email"
              as={TextField}
              name="userEmail"
              required
              size="small"
              placeholder="Reassign User Email"
              fullWidth
              autoFocus
              error={!!error}
              helperText={error}
            />
            <Button variant="contained" type="submit" form={formId}>
              Submit
            </Button>
          </Stack>
        </RevivnApiForm>
      </Popover>
    </>
  );
}
