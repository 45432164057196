import { Alert, AlertTitle, Box, Button, MenuItem, Stack, TextField, Typography, useTheme } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "services/api.service";
import { formatDate, parseTimeToAmPm } from "utils/helpers";
import { LabeledField } from "components/Form/LabeledField";
import * as Yup from "yup";
import EditAddressForm from "components/AssetRetrievals/EditAddressForm";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AssetRetrieval } from "types/assetRetrieval";

export const validationSchema = Yup.object({
  returnRecipientAddress: Yup.string()
    .required("Address Line 1 is required.")
    .min(5, "Address must be at least 5 characters."),
  returnRecipientAddressTwo: Yup.string(),
  returnRecipientAddressCity: Yup.string().required("City is required."),
  returnRecipientAddressState: Yup.string().required("State is required."),
  returnRecipientAddressZipCode: Yup.string()
    .required("Zip Code is required.")
    .matches(/^\d{5}$/, "Zip Code must be a 5-digit number."),
});

const addressChangeReasons = [
  "Incorrect employee address",
  "Incorrect company address",
  "Return label missing from kit",
  "PO Box",
  "Other",
];

export function AssetRetrievalEditPage({}: {}) {
  const params = useParams<{ assetRetrievalUuid: string }>();
  const navigate = useNavigate();
  const theme = useTheme();

  const { data, error } = params.assetRetrievalUuid
    ? useSWR<AssetRetrieval>(`asset_retrievals/${params.assetRetrievalUuid}`, fetcher)
    : { data: null, error: null };
  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Outbound Shipping", to: "/asset_retrievals?asset_retrieval_status=open" },
          {
            label: "Edit Order Addresses",
            to: `/asset_retrievals?asset_retrieval_status=open/${params.assetRetrievalUuid}`,
          },
        ]}
      ></Breadcrumbs>

      <Box mt={4}>
        <Typography fontSize={20} fontWeight={700}>
          Edit Order Addresses
        </Typography>
      </Box>

      {data && (
        <>
          <Box
            sx={{
              mt: 2,
              py: 3,
              px: 4,
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
            }}
          >
            <Typography fontSize={16} fontWeight={700}>
              Order Details
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                gap: 4,
                alignItems: "start",
                [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
              }}
            >
              <>
                <Stack direction="column" gap={1}>
                  <Typography variant="overline" fontSize={12} color="GrayText">
                    Order Number
                  </Typography>
                  <Typography variant="body1" fontSize={14}>
                    {data.orderNumber}
                  </Typography>
                </Stack>
                <Stack direction="column" gap={1}>
                  <Typography variant="overline" fontSize={12} color="GrayText">
                    Status
                  </Typography>
                  <Typography variant="body1" fontSize={14}>
                    {data.state}
                  </Typography>
                </Stack>
                <Stack direction="column" gap={1}>
                  <Typography variant="overline" fontSize={12} color="GrayText">
                    Date Placed
                  </Typography>
                  <Typography variant="body1" fontSize={14}>
                    {formatDate(data.createdAt)}
                  </Typography>
                </Stack>
                <Stack direction="column" gap={1}>
                  <Typography variant="overline" fontSize={12} color="GrayText">
                    Time
                  </Typography>
                  <Typography variant="body1" fontSize={14}>
                    {parseTimeToAmPm(data.createdAt)}
                  </Typography>
                </Stack>
                <Stack direction="column" gap={1}>
                  <Typography variant="overline" fontSize={12} color="GrayText">
                    Company
                  </Typography>
                  <Typography variant="body1" fontSize={14}>
                    {data.organization.name}
                  </Typography>
                </Stack>
              </>
            </Box>
          </Box>
          <Box my={4}>
            <RevivnApiForm
              initialValues={{
                ...data,
                addressChangeReasons: "",
              }}
              action={`asset_retrievals/${params.assetRetrievalUuid}`}
              method="PUT"
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: 4,
                  alignItems: "",
                  [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
                }}
              >
                <Box
                  sx={{
                    py: 3,
                    px: 4,
                    background: "white",
                    borderRadius: 1,
                    border: `1px solid ${theme.palette.divider}`,
                    [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
                  }}
                >
                  <Stack direction="row" alignItems="center" gap={2}>
                    <ArrowForwardIcon />
                    <Typography fontSize={16} fontWeight={600}>
                      To (Employee) Address
                    </Typography>
                  </Stack>
                  <Typography>Location where laptop is retrieved </Typography>
                  <EditAddressForm formType="employee" />
                </Box>
                <Box
                  sx={{
                    py: 3,
                    px: 4,
                    background: "white",
                    borderRadius: 1,
                    border: `1px solid ${theme.palette.divider}`,
                    [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
                  }}
                >
                  <Stack direction="row" alignItems="center" gap={2}>
                    <ArrowForwardIcon />
                    <Typography fontSize={16} fontWeight={600}>
                      Return Address
                    </Typography>
                  </Stack>
                  <Typography>Final destination for the retrieved laptop</Typography>

                  {data.location && data.location.id ? (
                    <Box mt={2} sx={{ display: "flex", flexDirection: "column", gap: 2, alignContent: "center" }}>
                      <Alert severity="info">
                        <AlertTitle
                          sx={{ fontWeight: 600 }}
                        >{`This box is going back to ${data.virtualPickup ? "Revivn" : "Clients"} Office `}</AlertTitle>

                        <Box>
                          <Typography>{data.location.address}</Typography>
                          <Typography>{data.location.address2}</Typography>
                          <Typography>{data.location.city}</Typography>
                          <Typography>{data.location.zipcode}</Typography>
                          <Typography>{data.location.country}</Typography>
                        </Box>
                      </Alert>
                      <Box sx={{ display: "grid", gridTemplateColumns: "2fr 2fr", gap: 2 }}>
                        <LabeledField name="returnRecipientFirstName" label="First Name" />
                        <LabeledField name="returnRecipientLastName" label="Last Name" />
                      </Box>
                      <LabeledField name="returnRecipientEmail" label="Email" />
                    </Box>
                  ) : (
                    <EditAddressForm formType="returnRecipient" />
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  py: 3,
                  px: 4,
                  my: 4,
                  background: "white",
                  borderRadius: 1,
                  border: `1px solid ${theme.palette.divider}`,
                  [theme.breakpoints.down("lg")]: { px: 2, py: 2 },
                }}
              >
                <Stack direction="column" gap={2} alignItems="left">
                  <Typography fontSize={16} fontWeight={600}>
                    Reason for Address Change *
                  </Typography>
                  <Typography fontSize={14} fontWeight={400}>
                    Select the reason you’re updating this the addresses associated with this order
                  </Typography>
                  <Field as={TextField} id="outlined-select-currency" select size="small" name="addressChangeReasons">
                    {addressChangeReasons.map((option, i) => (
                      <MenuItem key={i} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Field>
                </Stack>
              </Box>

              <Stack direction="row" gap={2} alignItems="left">
                <SubmitAwareButton type="submit" variant="contained">
                  Save
                </SubmitAwareButton>
                <Button variant="outlined" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </Stack>
            </RevivnApiForm>
          </Box>
        </>
      )}
    </Box>
  );
}
