import { v4 as uuid } from "uuid";
import { defaultRecordHistoryEvent, RecordHistoryEvent } from "./recordHistoryEvent";
import { defaultPickup, Pickup } from "./pickup";
import { Tag } from "./tag";

export type InventoryImage = {
  id: number;
  inventoryId?: number;
  inventory_id?: number;
  url: string;
};

export type InventoryWarehouse = {
  id: number;
  name: string;
};

export type Attachment = {
  signed_id?: string;
  id?: number;
  url?: string;
  filename?: string;
};

export type Inventory = {
  id: number;
  uuid: string;
  manufacturer: string;
  serialNumber: string;
  warehouseLocation: string;
  condition: string;
  color: string;
  sku: string;
  ram: string;
  cpu: string;
  hardDrive: string;
  batteryCount?: number;
  batteryHealth?: number;
  listed?: string;
  model?: string;
  modelOrder?: string;
  securityLevel?: string;
  warehouseId: number;
  description?: string;
  comments?: string;
  keyboardIsWorking?: boolean | null;
  trackpadIsWorking?: boolean | null;
  batteryIsWorking?: boolean | null;
  lcdDisplayIsWorking?: boolean | null;
  keyboardFunctionality?: string | null;
  trackpadFunctionality?: string | null;
  batteryFunctionality?: string | null;
  screenFunctionality?: string | null;
  cameraFunctionality?: string | null;
  microphoneFunctionality?: string | null;
  speakerFunctionality?: string | null;
  portsFunctionality?: string | null;
  powerFunctionality?: string | null;
  logicFunctionality?: string | null;
  inventoryEvents: Array<RecordHistoryEvent>;
  pickup: Pickup;
  tags?: Tag[];
  year?: number;
  printedAt?: string;
  deviceType?: string;
  state: string;
  iqInventoryId?: number;
  iqPoIdLine?: string;
  lineId: number;
  assetTag?: string;
  inventoryImages: InventoryImage[];
  quantity: number;
  gpu: string;
  label?: string;
  enrolledStatus: string;
  inventoryGroup?: string;
  manifestId?: string;
  warehouse?: InventoryWarehouse;
  hardDriveSizeInGb?: number | string;
  enrolledOrganization?: string;
  drive1ErasureStatus?: string;
  screenSize?: string;
  resalePhotos: Attachment[];
  resalePhotosOrder: number[];
};

export const defaultInventory = {
  id: 0,
  uuid: "",
  manufacturer: "",
  serialNumber: "",
  warehouseLocation: "",
  condition: "",
  color: "",
  sku: "",
  ram: "",
  cpu: "",
  hardDrive: "",
  inventoryEvents: [defaultRecordHistoryEvent],
  pickup: defaultPickup,
  tags: [],
  inventoryImages: [],
  state: "",
  iqInventoryId: 0,
  iqPoIdLine: "",
  lineId: 0,
  warehouseId: 0,
  assetTag: "",
  quantity: 0,
  gpu: "",
  enrolledStatus: "",
  enrolledOrganization: "",
  hardDriveSizeInGb: "",
  resalePhotos: [],
  resalePhotosOrder: [],
};

export const defaultTechnicianInventory: Inventory = {
  id: 0,
  uuid: "",
  manufacturer: "",
  serialNumber: "",
  warehouseLocation: "",
  condition: "",
  color: "",
  sku: "",
  ram: "",
  cpu: "",
  hardDrive: "",
  model: "",
  modelOrder: "",
  securityLevel: "",
  warehouseId: 0,
  description: "",
  comments: "",
  batteryCount: 0,
  batteryHealth: 0,
  tags: [],
  trackpadIsWorking: null,
  keyboardIsWorking: null,
  batteryIsWorking: null,
  lcdDisplayIsWorking: null,
  trackpadFunctionality: "",
  keyboardFunctionality: "",
  batteryFunctionality: "",
  screenFunctionality: "",
  cameraFunctionality: "",
  microphoneFunctionality: "",
  speakerFunctionality: "",
  portsFunctionality: "",
  powerFunctionality: "",
  logicFunctionality: "",
  inventoryEvents: [defaultRecordHistoryEvent],
  pickup: defaultPickup,
  inventoryImages: [],
  state: "",
  iqInventoryId: 0,
  iqPoIdLine: "",
  lineId: 0,
  quantity: 0,
  gpu: "",
  enrolledStatus: "",
  enrolledOrganization: "",
  hardDriveSizeInGb: "",
  resalePhotos: [],
  resalePhotosOrder: [],
};

export type InventoryAddProps = {
  id: string;
  inventory_template_uuid: string;
  quantity: string;
  print_labels: boolean;
};

export const defaultInventoryAdd = () => {
  return {
    id: uuid(),
    inventory_template_uuid: "",
    quantity: "1",
    print_labels: true,
  };
};

export type InventoryBulkEditProps = {
  id: number;
  warehouseLocation?: string;
  ram?: string;
  serialNumber?: string;
  sku?: string;
};

export interface IndexedInventory {
  id: number;
  uuid: string;
  label: string;
  pickupId: number;
  lineId: number;
  warehouseLocation: string;
  serialNumber: string;
  manufacturer: string;
  deviceType: string;
  sku: string;
  color: string;
  quantity: number;
  model: string;
  modelOrder: string;
  cpu: string;
  ram: string;
  ramSizeInGb: number;
  hardDrive: string;
  hardDriveSizeInGb: number;
  condition: string;
  screenSize: string;
  screenResolution: string;
  assetTag: string;
  gpu: string;
  year: number;
  state: string;
  enrolledStatus: string;
  securityLevel: string;
  netPriceInCents: number;
  batteryCount: number;
  comments: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  unenrolledAt: string;
  unenrolledById: string;
  createdById: string;
  enrolledOrganization: string;
  warehouseName: string;
  partnerId: string;
  tagNames: string;
}
