import { Box, styled, Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { FileUpload } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { FileField } from "components/Form/FileField";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  height: "60vh",
  backgroundColor: "background.paper",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.mode == "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
  },
}));

const AutoSubmitFile = () => {
  // Grab values and submitForm from context
  const { values, submitForm } = useFormikContext<any>();
  useEffect(() => {
    // Submit the form imperatively as an effect as soon as form
    if (values.vendor_list_upload.file instanceof File) {
      submitForm();
    }
  }, [values, submitForm]);
  return null;
};

interface UploadReport {
  status: string;
  errors: string[];
  vendorId: number;
  name: string;
  organizationId?: number;
  organizationName?: string;
  orgOnboarded: string;
  airbaseOnboarded: string;
  primaryEmail: string;
  orgAccountingEmail?: string;
}

// Show a datagrid of the upload report
function AirbaseVendorListUploadReport({ report }: { report: UploadReport[] }) {
  return (
    <StyledDataGrid
      rows={report}
      components={{
        Toolbar: GridToolbar,
      }}
      columns={[
        { field: "vendorId", headerName: "Vendor ID", width: 150 },
        { field: "name", headerName: "Name", flex: 0.5 },
        { field: "primaryEmail", headerName: "Airbase Email", flex: 0.5 },
        { field: "airbaseOnboarded", headerName: "Airbase Onboarded?", width: 150 },
        { field: "organizationName", headerName: "Phx Name", flex: 0.5 },
        { field: "organizationId", headerName: "Phx Org ID", width: 150 },
        { field: "orgOnboarded", headerName: "Phx Onboarded?", width: 150 },
        { field: "orgAccountingEmail", headerName: "Phx Accounting Email", flex: 0.5 },
        { field: "status", headerName: "Status", width: 150 },
        { field: "errors", headerName: "Errors", flex: 1 },
      ]}
      getRowId={(row) => row.vendorId}
    />
  );
}

export function AirbaseVendorListUpload({}: {}) {
  const [report, setReport] = useState<UploadReport[] | null>(null);

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Finance", to: "/finance" },
          {
            label: "Clients",
            to: "/finance/clients",
          },
          { label: "Airbase Vendor List Upload" },
        ]}
      ></Breadcrumbs>

      <Box mt={4}>
        <Typography variant="h4">Airbase Vendor List Upload</Typography>
        <Typography mt={0.5}>Upload the Vendor List CSV from Airbase to update client airbase statuses.</Typography>
      </Box>

      <Box my={4} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <RevivnApiForm
          method="post"
          action="/airbase/vendor_list_upload"
          initialValues={{ vendor_list_upload: { file: null } }}
          enctype="multipart/form-data"
          onSuccess={({ data }) => setReport(data.report)}
          onSettled={(formik) => formik.resetForm()}
        >
          <SubmitAwareButton component={"label"} variant="contained" startIcon={<FileUpload />}>
            <FileField type="file" name="vendor_list_upload.file" accept=".csv" hidden />
            Upload
          </SubmitAwareButton>
          <AutoSubmitFile />
        </RevivnApiForm>
      </Box>
      {report && (
        <>
          <h2>Upload Report</h2>
          <AirbaseVendorListUploadReport report={report} />
        </>
      )}
    </Box>
  );
}
