import { GridColType } from "@mui/x-data-grid-pro";

type SingleValueSerializer = (value: string) => string;
type MultiValueSerializer = (value: string[]) => string;

interface Operator {
  operatorValue: string;
  matcher: RegExp;
  columnTypes?: GridColType[];
  valueRequired: boolean;
  escapeWhitespace: boolean;
  multiValue: boolean;
}

interface SingleValueOperator extends Operator {
  multiValue: false;
  serializer: SingleValueSerializer;
}

interface MultiValueOperator extends Operator {
  multiValue: true;
  serializer: MultiValueSerializer;
}

export const operators: Array<SingleValueOperator | MultiValueOperator> = [
  {
    operatorValue: "isEmpty",
    columnTypes: ["string", "number", "date"],
    valueRequired: false,
    matcher: /^\(NOT \*\)$/,
    multiValue: false,
    escapeWhitespace: false,
    serializer: () => `(NOT *)`,
  },
  {
    operatorValue: "not",
    matcher: /^\(NOT "(.*)"\)$/,
    valueRequired: true,
    multiValue: false,
    columnTypes: ["date", "singleSelect"],
    escapeWhitespace: false,
    serializer: (value: string) => `(NOT "${value}")`,
  },
  {
    operatorValue: "notEquals",
    matcher: /^\(NOT "(.*)"\)$/,
    columnTypes: ["string"],
    valueRequired: true,
    multiValue: false,
    escapeWhitespace: false,
    serializer: (value: string) => `(NOT "${value}")`,
  },
  {
    operatorValue: "!=",
    matcher: /^\(NOT "(.*)"\)$/,
    columnTypes: ["number"],
    valueRequired: true,
    multiValue: false,
    escapeWhitespace: false,
    serializer: (value: string) => `(NOT "${value}")`,
  },
  {
    operatorValue: "isNotEmpty",
    matcher: /^\*$/,
    columnTypes: ["string", "number", "date"],
    valueRequired: false,
    multiValue: false,
    escapeWhitespace: false,
    serializer: () => `*`,
  },
  {
    operatorValue: "=",
    matcher: /^"(.*)"$/,
    columnTypes: ["number"],
    valueRequired: true,
    multiValue: false,
    escapeWhitespace: false,
    serializer: (value: string) => `"${value}"`,
  },
  {
    operatorValue: "equals",
    matcher: /^"(.*)"$/,
    columnTypes: ["string"],
    valueRequired: true,
    multiValue: false,
    escapeWhitespace: false,
    serializer: (value: string) => `"${value}"`,
  },
  {
    operatorValue: "is",
    matcher: /^"(.*)"$/,
    valueRequired: true,
    columnTypes: ["boolean", "date", "singleSelect"],
    multiValue: false,
    escapeWhitespace: false,
    serializer: (value: string) => `"${value}"`,
  },
  {
    operatorValue: "contains",
    matcher: /^\*(.*)\*$/,
    columnTypes: ["string"],
    valueRequired: true,
    multiValue: false,
    escapeWhitespace: true,
    serializer: (value: string) => `*${value}*`,
  },
  {
    operatorValue: "startsWith",
    matcher: /^(.*)\*$/,
    columnTypes: ["string"],
    valueRequired: true,
    multiValue: false,
    escapeWhitespace: true,
    serializer: (value: string) => `${value}*`,
  },
  {
    operatorValue: "endsWith",
    matcher: /^\*(.*)$/,
    columnTypes: ["string"],
    valueRequired: true,
    multiValue: false,
    escapeWhitespace: true,
    serializer: (value: string) => `*${value}`,
  },
  {
    operatorValue: "onOrAfter",
    matcher: /^>=(.*)$/,
    valueRequired: true,
    multiValue: false,
    columnTypes: ["date"],
    escapeWhitespace: true,
    serializer: (value: string) => `>=${value}`,
  },
  {
    operatorValue: ">=",
    matcher: /^>=(.*)$/,
    valueRequired: true,
    multiValue: false,
    columnTypes: ["number"],
    escapeWhitespace: true,
    serializer: (value: string) => `>=${value}`,
  },
  {
    operatorValue: "onOrBefore",
    matcher: /^<=(.*)$/,
    valueRequired: true,
    multiValue: false,
    columnTypes: ["date"],
    escapeWhitespace: true,
    serializer: (value: string) => `<=${value}`,
  },
  {
    operatorValue: "<=",
    matcher: /^<=(.*)$/,
    valueRequired: true,
    columnTypes: ["number"],
    multiValue: false,
    escapeWhitespace: true,
    serializer: (value: string) => `<=${value}`,
  },
  {
    operatorValue: "after",
    matcher: /^>(.*)$/,
    valueRequired: true,
    multiValue: false,
    columnTypes: ["date"],
    escapeWhitespace: true,
    serializer: (value: string) => `>${value}`,
  },
  {
    operatorValue: ">",
    matcher: /^>(.*)$/,
    valueRequired: true,
    columnTypes: ["number"],
    multiValue: false,
    escapeWhitespace: true,
    serializer: (value: string) => `>${value}`,
  },
  {
    operatorValue: "before",
    matcher: /^<(.*)$/,
    valueRequired: true,
    multiValue: false,
    columnTypes: ["date"],
    escapeWhitespace: true,
    serializer: (value: string) => `<${value}`,
  },
  {
    operatorValue: "<",
    matcher: /^<(.*)$/,
    valueRequired: true,
    columnTypes: ["number"],
    multiValue: false,
    escapeWhitespace: true,
    serializer: (value: string) => `<${value}`,
  },
  {
    operatorValue: "isAnyOf",
    matcher: /\w+(?=\s+OR|\s*\))/g,
    columnTypes: ["string", "number", "singleSelect"],
    valueRequired: true,
    multiValue: true,
    escapeWhitespace: true,
    serializer: (value: string[]) => `(${value.join(" OR ")})`,
  },
];

export const operatorsThatRequireValue = operators.filter((op) => op.valueRequired).map((op) => op.operatorValue);
