import { Close } from "@mui/icons-material";
import { Alert, Box, Button, IconButton, Modal as MUIModal, Typography } from "@mui/material";
import { Attachment, DriveFolderUploadOutlined, CloseOutlined } from "@mui/icons-material";
import FileDropSection from "components/common/FileDropSection";
import useDirectUpload from "hooks/useDirectUpload";
import { useCallback, useEffect, useState } from "react";
import CSVFileValidator, { RowError } from "csv-file-validator";
import Papa, { ParseResult } from "papaparse";
import { api } from "services/api.service";
import { useAlert } from "hooks/useAlert";

const csvHeaders = [
  { name: "merchant_product_number", inputName: "merchant_product_number", optional: true },
  { name: "inv_group", inputName: "inv_group", required: true },
  { name: "sku", inputName: "sku", optional: true },
  { name: "approved_price", inputName: "approved_price", required: true },
];

const config = {
  headers: csvHeaders,
  isHeaderNameOptional: false,
  isColumnIndexAlphabetic: false,
};

const blobToFile = (blob: Blob, fileName: string): File => {
  return new File([blob], fileName, { type: blob.type });
};

const triggerBrowserDownload = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const Errors = ({ errors }: { errors: string[] }) => {
  return (
    <Box
      sx={{
        my: 2,
        border: "1px solid #ef9a9a",
        borderRadius: "4px",
        p: 1,
        height: "70px",
        overflow: "auto",
      }}
    >
      <Alert severity="error" sx={{ mb: 2 }}>
        {errors.map((error, index) => (
          <div key={index}>{error}</div>
        ))}
      </Alert>
    </Box>
  );
};

interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function UploadPricingModal({ open, setOpen }: ModalProps) {
  const { upload, blob, reset } = useDirectUpload({
    keyPrefix: "/product_catalog/pricing_history/",
    service: "amazon_public",
  });
  const { alertSuccess } = useAlert();
  const [clientValidationErrors, setClientValidationErrors] = useState<RowError[]>([]);
  const [errorFileUrl, setErrorFileUrl] = useState<string | null>(null);

  useEffect(() => {
    if (blob && blob.byte_size === 0) {
      setClientValidationErrors([
        { message: "No valid rows were found in the file. Please verify file rows and column names." },
      ]);
    }
  }, [blob]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCreateUpload = useCallback(() => {
    api.post("/product_prices_uploads", { signed_id: blob?.signed_id }).then(({ data }) => {
      if (data.valid) {
        reset();
        setOpen(false);
        alertSuccess("Price sheet uploaded successfully");
      } else {
        setErrorFileUrl(data.errorsFileUrl);
      }
    });
  }, [blob, reset, setOpen, alertSuccess]);

  const handleFileUpload = useCallback(
    (file: File) => {
      setClientValidationErrors([]);
      Papa.parse(file, {
        header: true,
        complete: function (results: ParseResult<Record<string, string>>) {
          const requiredHeaders = csvHeaders.map((header) => header.name);
          let filteredData = results.data.map((row) =>
            requiredHeaders.reduce(
              (acc, header) => {
                if (row[header] !== undefined) {
                  acc[header] = row[header];
                }
                return acc;
              },
              {} as Record<string, string>,
            ),
          );

          filteredData = filteredData.filter((row) =>
            Object.values(row).some((value) => value !== undefined && value !== ""),
          );

          const filteredCSV = Papa.unparse(filteredData, {
            header: true,
          });

          const filteredBlob = new Blob([filteredCSV], { type: "text/csv" });
          const filteredFile = blobToFile(filteredBlob, "filtered_" + file.name);

          CSVFileValidator(filteredFile, config).then((csvData) => {
            if (csvData.inValidData.length > 0) {
              setClientValidationErrors(csvData.inValidData);
            } else {
              upload(file);
            }
          });
        },
      });
    },
    [upload],
  );

  return (
    <MUIModal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "661px",
          height: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          pt: 4.5,
          px: 3,
          pb: 4,
        }}
      >
        <IconButton onClick={handleClose} sx={{ position: "absolute", top: "4px", right: "4px" }}>
          <Close />
        </IconButton>

        <Box>
          <Typography fontSize={"22px"} fontWeight={800} sx={{ mb: 1 }}>
            Upload Price Sheet
          </Typography>
          <Typography fontSize={"17px"} fontWeight={400} sx={{ mb: 3 }}>
            Upload your price sheet to update prices in the product catalog.
          </Typography>

          <Box mt={4}>
            {blob ? (
              <Box sx={{ display: "flex", flexDirection: "column", width: "fit-content" }}>
                <Box
                  key={blob?.signed_id}
                  component="a"
                  href={blob?.signed_id}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    border: (theme) => `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "4px",
                    p: 1,
                    pr: 3,
                    color: (theme) => theme.palette.primary.main,
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    fontFamily: "Catamaran",
                    textDecoration: "none",
                    position: "relative",
                    width: "fit-content",
                  }}
                >
                  <Attachment />
                  {blob.filename}
                  <Box
                    component="button"
                    sx={{
                      height: "24px",
                      width: "24px",
                      background: (theme) => theme.palette.primary.main,
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: 0,
                      borderRadius: "100%",
                      position: "absolute",
                      top: "-4px",
                      right: "-8px",
                      cursor: "pointer",
                    }}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      e.preventDefault();
                      reset();
                      setClientValidationErrors([]);
                      setErrorFileUrl("");
                    }}
                  >
                    <CloseOutlined />
                  </Box>
                </Box>
              </Box>
            ) : (
              <FileDropSection
                icon={<DriveFolderUploadOutlined color="primary" sx={{ fontSize: "32px" }} />}
                acceptHint={"csv"}
                accept={{ "text/csv": [] }}
                onUpload={([file]) => handleFileUpload(file)}
              />
            )}
          </Box>
          {blob && !errorFileUrl && (
            <Alert severity="info" sx={{ mt: 2 }}>
              Clicking "Upload" will update the prices for all products listed in this spreadsheet. Please confirm that
              the prices and product details are accurate before proceeding.
            </Alert>
          )}
          {errorFileUrl && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Error uploading price sheet.
              <Button
                color="error"
                variant="text"
                size="small"
                onClick={() => {
                  triggerBrowserDownload(errorFileUrl, "errors.csv");
                }}
              >
                Download error file
              </Button>
            </Alert>
          )}
          {clientValidationErrors.length > 0 && (
            <Errors errors={clientValidationErrors.map((error) => error.message)} />
          )}
          <Box display="flex" justifyContent="center" mt={clientValidationErrors.length > 0 ? 2 : 4}>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ ml: 2 }}
              disabled={!blob || clientValidationErrors.length > 0}
              onClick={handleCreateUpload}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </Box>
    </MUIModal>
  );
}
