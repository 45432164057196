import {
  Box,
  FormControl,
  Button,
  MenuItem,
  Typography,
  Autocomplete,
  TextField,
  Divider,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import { logisticsTeam } from "constants/logisticsTeam";
import { useFormik } from "formik";
import useAgents from "hooks/useAgents";
import useFreighters from "hooks/useFreighters";
import useItads from "hooks/useItads";
import useLogisticsDetails from "hooks/useLogisticsDetails";

import { defaultLogisticsPartner, LogisticsPartner } from "types/logisticsPartner";

// We do not anticipate exceeding 100 records for agents, itads, or freighters,
// but if we do, we can either increase this value or update hooks to paginate
// through all results.
const LOAD_ALL_RECORDS_PAGE_SIZE = 100;

export default function Form({ updateLogisticsDetails, logisticsDetails }: ReturnType<typeof useLogisticsDetails>) {
  const { mainAgents, subAgents } = useAgents(LOAD_ALL_RECORDS_PAGE_SIZE);
  const { itads } = useItads(LOAD_ALL_RECORDS_PAGE_SIZE);
  const { mainFreighters, subFreighters } = useFreighters(LOAD_ALL_RECORDS_PAGE_SIZE);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      agentId: logisticsDetails?.agentId || "",
      subAgentId: logisticsDetails?.subAgentId || "",
      agentContactId: logisticsDetails?.agentContactId || "",
      revivnCoordinatorId: logisticsDetails?.revivnCoordinatorId || "",
      orderNumber: logisticsDetails?.orderNumber || "",
      estimatedCostInCents:
        (logisticsDetails?.estimatedCostInCents && logisticsDetails?.estimatedCostInCents / 100.0) || null,
      postEstimatedCostInCents:
        (logisticsDetails?.postEstimatedCostInCents && logisticsDetails?.postEstimatedCostInCents / 100.0) || null,
      costInCents: (logisticsDetails?.costInCents && logisticsDetails?.costInCents / 100.0) || null,
      agentConversationLink: logisticsDetails?.agentConversationLink || "",
      itadId: logisticsDetails?.itadId || "",
      itadLocationId: logisticsDetails?.itadLocationId || "",
      itadOrderId: logisticsDetails?.itadOrderId || "",
      itadPaymentInCents:
        (logisticsDetails?.itadPaymentInCents && logisticsDetails?.itadPaymentInCents / 100.0) || null,
      freighterId: logisticsDetails?.freighterId || "",
      subFreighterId: logisticsDetails?.subFreighterId || "",
      freighterOrderId: logisticsDetails?.freighterOrderId || "",
      freighterConversationLink: logisticsDetails?.freighterConversationLink || "",
      palletsCount: logisticsDetails?.palletsCount || "",
      internalNotes: logisticsDetails?.internalNotes || "",
      logisticsClickupLink: logisticsDetails?.logisticsClickupLink || "",
      logisticsClientConversationLink: logisticsDetails?.logisticsClientConversationLink || "",
      itadConversationLink: logisticsDetails?.itadConversationLink || "",
      pickupDate: logisticsDetails?.pickupDate || "",
      dropoffEta: logisticsDetails?.dropoffEta || "",
      dropoffDate: logisticsDetails?.dropoffDate || "",
      weightInLbs: logisticsDetails?.weightInLbs || "",
      miles: logisticsDetails?.miles || "",
      deliveryType: logisticsDetails?.deliveryType || "",
      bol: logisticsDetails?.bol || "",
      bolLink: logisticsDetails?.bolLink || "",
      estimatedInvoiceCostInCents:
        (logisticsDetails?.estimatedInvoiceCostInCents && logisticsDetails?.estimatedInvoiceCostInCents / 100.0) ||
        null,
      secondEstimatedInvoiceCostInCents:
        (logisticsDetails?.secondEstimatedInvoiceCostInCents &&
          logisticsDetails?.secondEstimatedInvoiceCostInCents / 100.0) ||
        "",
      invoiceCostInCents:
        (logisticsDetails?.invoiceCostInCents && logisticsDetails?.invoiceCostInCents / 100.0) || null,
    },

    onSubmit: async (values) => {
      updateLogisticsDetails(values);
    },
  });

  const findAgent = (id: string, type: string) => {
    if (type === "subAgents") {
      return subAgents.find((a: LogisticsPartner) => a.id === id) || defaultLogisticsPartner;
    } else {
      return mainAgents.find((a: LogisticsPartner) => a.id === id) || defaultLogisticsPartner;
    }
  };

  const findItad = (id: string) => {
    return itads.find((a: LogisticsPartner) => a.id === id) || defaultLogisticsPartner;
  };

  const findFreighter = (id: string, type: string) => {
    if (type === "subFreighters") {
      return subFreighters.find((a: LogisticsPartner) => a.id === id) || defaultLogisticsPartner;
    } else {
      return mainFreighters.find((a: LogisticsPartner) => a.id === id) || defaultLogisticsPartner;
    }
  };

  return (
    <div>
      <Box component="form" onSubmit={formik.handleSubmit} noValidate autoComplete="off">
        <Box
          sx={{
            p: 4,
            background: "background.paper",
            borderRadius: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            marginBottom: "20px",
          }}
        >
          <Typography variant="h6" fontSize="18px" fontWeight="bold" sx={{ paddingBottom: "20px" }}>
            1. Agent Details:
          </Typography>

          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="agent">
                Agent:
              </InputLabel>
              <Autocomplete
                id="agentId"
                getOptionLabel={(option: LogisticsPartner) => option.name}
                isOptionEqualToValue={(option: LogisticsPartner, value: LogisticsPartner) => option.id === value.id}
                value={findAgent(formik.values.agentId, "mainAgents")}
                options={
                  findAgent(formik.values.agentId, "mainAgents") !== defaultLogisticsPartner
                    ? mainAgents
                    : [defaultLogisticsPartner, ...mainAgents]
                }
                autoComplete
                includeInputInList
                filterSelectedOptions
                onChange={(event, newValue: any) => {
                  formik.setFieldValue("agentId", newValue?.id || "");
                }}
                renderInput={(params) => <TextField {...params} name="agentId" label="Search Agents" fullWidth />}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
              />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="subAgent">
                Sub Agent:
              </InputLabel>
              <Autocomplete
                id="subAgentId"
                getOptionLabel={(option: LogisticsPartner) => option.name}
                isOptionEqualToValue={(option: LogisticsPartner, value: LogisticsPartner) => option.id === value.id}
                value={findAgent(formik.values.subAgentId, "subAgents")}
                options={
                  findAgent(formik.values.subAgentId, "subAgents") !== defaultLogisticsPartner
                    ? subAgents
                    : [defaultLogisticsPartner, ...subAgents]
                }
                autoComplete
                includeInputInList
                filterSelectedOptions
                onChange={(event, newValue: any) => {
                  formik.setFieldValue("subAgentId", newValue?.id || "");
                }}
                renderInput={(params) => (
                  <TextField {...params} name="subAgentId" label="Search Sub Agents" fullWidth />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
              />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="revivnCoordinatorId">
                Revivn Coordinator:
              </InputLabel>
              <AppSelect defaultValue="" id="revivnCoordinatorId" {...formik.getFieldProps("revivnCoordinatorId")}>
                {logisticsTeam.map((person) => {
                  return (
                    <MenuItem key={person[0]} value={person[0]}>
                      {person[1]}
                    </MenuItem>
                  );
                })}
              </AppSelect>
            </FormControl>
          </Box>
          <Divider sx={{ padding: "10px 0" }} />
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 3, padding: "20px 0" }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="name">
                Agent Order ID
              </InputLabel>
              <InputBase id="orderNumber" {...formik.getFieldProps("orderNumber")} placeholder="Order ID" />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="estimatedCostInCents">Estimated Invoice Cost</InputLabel>
              <OutlinedInput
                id="estimatedCostInCents"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                placeholder="0.00"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                {...formik.getFieldProps("estimatedCostInCents")}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="costInCents">Invoice Cost</InputLabel>
              <OutlinedInput
                id="costInCents"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                placeholder="0.00"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                {...formik.getFieldProps("costInCents")}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="postEstimatedCostInCents">Post Estimated Cost</InputLabel>
              <OutlinedInput
                id="postEstimatedCostInCents"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                placeholder="0.00"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                {...formik.getFieldProps("postEstimatedCostInCents")}
              />
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            p: 4,
            background: "background.paper",
            borderRadius: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            marginBottom: "20px",
          }}
        >
          <Typography variant="h6" fontSize="18px" fontWeight="bold">
            2. ITAD Details:
          </Typography>

          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="itad">
                ITAD:
              </InputLabel>
              <Autocomplete
                id="itadId"
                getOptionLabel={(option: LogisticsPartner) => option.name}
                isOptionEqualToValue={(option: LogisticsPartner, value: LogisticsPartner) => option.id === value.id}
                value={findItad(formik.values.itadId)}
                options={
                  findItad(formik.values.itadId) !== defaultLogisticsPartner
                    ? itads
                    : [defaultLogisticsPartner, ...itads]
                }
                autoComplete
                includeInputInList
                filterSelectedOptions
                onChange={(event, newValue: any) => {
                  formik.setFieldValue("itadId", newValue?.id || "");
                }}
                renderInput={(params) => <TextField {...params} name="itadId" label="Search Itads" fullWidth />}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="itadOrderId">
                ITAD Order ID:
              </InputLabel>
              <InputBase id="itadOrderId" {...formik.getFieldProps("itadOrderId")} placeholder="Order ID" />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="itadPaymentInCents">Itad Payment</InputLabel>
              <OutlinedInput
                id="itadPaymentInCents"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                placeholder="0.00"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                {...formik.getFieldProps("itadPaymentInCents")}
              />
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            p: 4,
            background: "background.paper",
            borderRadius: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            marginBottom: "20px",
          }}
        >
          <Typography variant="h6" fontSize="18px" fontWeight="bold">
            3. Freighter Details:
          </Typography>

          <Box sx={{ display: "grid", gridTemplateColumns: "2fr 2fr 2fr 1fr", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="freighter">
                Freighter:
              </InputLabel>
              <Autocomplete
                id="freighterId"
                getOptionLabel={(option: LogisticsPartner) => option.name}
                isOptionEqualToValue={(option: LogisticsPartner, value: LogisticsPartner) => option.id === value.id}
                value={findFreighter(formik.values.freighterId, "mainFreighters")}
                options={
                  findFreighter(formik.values.freighterId, "mainFreighters") !== defaultLogisticsPartner
                    ? mainFreighters
                    : [defaultLogisticsPartner, ...mainFreighters]
                }
                autoComplete
                includeInputInList
                filterSelectedOptions
                onChange={(event, newValue: any) => {
                  formik.setFieldValue("freighterId", newValue?.id || "");
                }}
                renderInput={(params) => (
                  <TextField {...params} name="freighterId" label="Search Freighters" fullWidth />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="subFreighter">
                Sub Freighter:
              </InputLabel>
              <Autocomplete
                id="subFreighterId"
                getOptionLabel={(option: LogisticsPartner) => option.name}
                isOptionEqualToValue={(option: LogisticsPartner, value: LogisticsPartner) => option.id === value.id}
                value={findFreighter(formik.values.subFreighterId, "subFreighters")}
                options={
                  findFreighter(formik.values.subFreighterId, "subFreighters") !== defaultLogisticsPartner
                    ? subFreighters
                    : [defaultLogisticsPartner, ...subFreighters]
                }
                autoComplete
                includeInputInList
                filterSelectedOptions
                onChange={(event, newValue: any) => {
                  formik.setFieldValue("subFreighterId", newValue?.id || "");
                }}
                renderInput={(params) => (
                  <TextField {...params} name="subFreighterId" label="Search Sub Freighters" fullWidth />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="freighterOrderId">
                Freighter Order ID:
              </InputLabel>
              <InputBase id="freighterOrderId" {...formik.getFieldProps("freighterOrderId")} placeholder="Order ID" />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="palletsCount">
                # of Pallets:
              </InputLabel>
              <InputBase type="number" id="palletsCount" {...formik.getFieldProps("palletsCount")} />
            </FormControl>
          </Box>
          <Divider sx={{ padding: "10px 0" }} />
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 3, padding: "20px 0" }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="bol">
                BOL #:
              </InputLabel>
              <InputBase id="bol" {...formik.getFieldProps("bol")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="bolLink">
                BOL Link:
              </InputLabel>
              <InputBase id="bolLink" {...formik.getFieldProps("bolLink")} />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="estimatedInvoiceCostInCents">Estimated Invoice Cost</InputLabel>
              <OutlinedInput
                id="invoiceCostInCents"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                placeholder="0.00"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                {...formik.getFieldProps("estimatedInvoiceCostInCents")}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="secondEstimatedInvoiceCostInCents">Second Estimated Invoice Cost</InputLabel>
              <OutlinedInput
                id="secondEstimatedInvoiceCostInCents"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                placeholder="0.00"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                {...formik.getFieldProps("secondEstimatedInvoiceCostInCents")}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="invoiceCostInCents">Invoice Cost</InputLabel>
              <OutlinedInput
                id="invoiceCostInCents"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                placeholder="0.00"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                {...formik.getFieldProps("invoiceCostInCents")}
              />
            </FormControl>
          </Box>
          <Divider sx={{ padding: "10px 0" }} />
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 3, padding: "20px 0" }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="pickupDate">
                Pickup Date:
              </InputLabel>
              <InputBase type="date" id="pickupDate" {...formik.getFieldProps("pickupDate")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="dropoffEta">
                Drop Off ETA:
              </InputLabel>
              <InputBase id="dropoffEta" type="date" {...formik.getFieldProps("dropoffEta")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="dropoffDate">
                Drop Off Date:
              </InputLabel>
              <InputBase id="dropoffDate" type="date" {...formik.getFieldProps("dropoffDate")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="weightInLbs">
                Weight:
              </InputLabel>
              <InputBase id="weightInLbs" {...formik.getFieldProps("weightInLbs")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="miles">
                Miles:
              </InputLabel>
              <InputBase id="miles" {...formik.getFieldProps("miles")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="deliveryType">
                Delivery Type:
              </InputLabel>
              <AppSelect id="deliveryType" {...formik.getFieldProps("deliveryType")} label="Delivery Type" autoWidth>
                {["AIR", "1-2 Days", "3-5 Days", "5+ Days", "Same Day"].map((deliveryType) => {
                  return <MenuItem value={deliveryType}>{deliveryType}</MenuItem>;
                })}
              </AppSelect>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            p: 4,
            background: "background.paper",
            borderRadius: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="h6" fontSize="18px" fontWeight="bold">
            4. Other Details:
          </Typography>

          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="logisticsClickupLink">
                Clickup Link:
              </InputLabel>
              <InputBase id="logisticsClickupLink" {...formik.getFieldProps("logisticsClickupLink")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="logisticsClientConversationLink">
                Client Conversation Link:
              </InputLabel>
              <InputBase
                id="logisticsClientConversationLink"
                {...formik.getFieldProps("logisticsClientConversationLink")}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="agentConversationLink">
                Agent Conversation Link:
              </InputLabel>
              <InputBase id="agentConversationLink" {...formik.getFieldProps("agentConversationLink")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="itadConversationLink">
                ITAD Conversation Link:
              </InputLabel>
              <InputBase id="itadConversationLink" {...formik.getFieldProps("itadConversationLink")} />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="freighterConversationLink">
                Freighter Conversation Link:
              </InputLabel>
              <InputBase id="freighterConversationLink" {...formik.getFieldProps("freighterConversationLink")} />
            </FormControl>
          </Box>
          <Divider sx={{ padding: "10px 0" }} />
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gap: 3, padding: "20px 0" }}>
            <FormControl variant="standard" fullWidth>
              <Typography variant="overline"> Internal Notes </Typography>
              <TextField
                id="internalNotes"
                multiline={true}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                {...formik.getFieldProps("internalNotes")}
              />
            </FormControl>
          </Box>
        </Box>
        <Box mt={4} sx={{ display: "flex", justifyContent: "start" }}>
          <Button color="primary" variant="contained" type="submit" sx={{ width: "100px" }}>
            SAVE
          </Button>
        </Box>
      </Box>
    </div>
  );
}
