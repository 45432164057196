import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import LogisticsPickupRow from "./LogisticsPickupRow";
import LogisticsPickupTabs from "./LogisticsPickupTabs";
import { Add } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { isValidElement, useCallback, useRef } from "react";
import useLogisticsPickups from "hooks/useLogisticsPickups";
import Breadcrumbs from "components/common/Breadcrumbs";
import {
  DataGridPro,
  getGridStringOperators,
  GridColDef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridValueGetterParams,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { Pickup } from "types/pickup";
import PickupStateChip from "components/PickupState";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { readableDate } from "utils/readableDate";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
};

const CustomDetailPanelToggle = (props: Pick<GridRenderCellParams, "id" | "value">) => {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);
  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton size="small" tabIndex={-1} disabled={!hasDetail} aria-label={isExpanded ? "Close" : "Open"}>
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
};

const columns: GridColDef[] = [
  {
    field: "scheduledAt",
    headerName: "Date",
    width: 125,
    sortable: true,
    valueGetter: (params: GridValueGetterParams) => {
      return readableDate(params.row.scheduledAt);
    },
  },
  {
    field: "confirmedTime",
    headerName: "Time",
    width: 90,
    sortable: true,
  },
  {
    field: "orderNumber",
    headerName: "External ID",
    width: 100,
    sortable: true,
  },
  {
    field: "id",
    headerName: "Internal ID",
    width: 100,
    sortable: true,
  },
  {
    field: "pickup_details.agent",
    headerName: "Agent",
    width: 120,
    filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    valueGetter: (params: GridValueGetterParams<Pickup, Pickup>) => {
      return params.row.pickupAgent?.agent?.name;
    },
  },
  {
    field: "pickup_details.itad",
    headerName: "ITAD",
    width: 120,
    filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    valueGetter: (params: GridValueGetterParams<Pickup, Pickup>) => {
      return params.row.pickupItad?.itad?.name;
    },
  },
  {
    field: "pickup_details.coordinator",
    headerName: "Coordinator",
    width: 100,
    filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    valueGetter: (params: GridValueGetterParams<Pickup, Pickup>) => {
      return params.row.revivnCoordinator?.firstName;
    },
  },
  {
    field: "organizations.name",
    headerName: "Company",
    width: 200,
    sortable: true,
    valueGetter: (params: GridValueGetterParams<Pickup, Pickup>) => {
      return params.row.organization?.name;
    },
  },
  {
    field: "location",
    headerName: "Location",
    minWidth: 450,
    flex: 1,
    sortable: false,
    filterable: false,
    valueGetter: ({ row: { location } }: GridValueGetterParams<Pickup, Pickup>) => {
      return (
        location && `${location.address || ""} ${location.city || ""} ${location.state || ""} ${location.zipcode || ""}`
      );
    },
  },
  {
    field: "state",
    headerName: "State",
    width: 250,
    sortable: true,
    renderCell({ row: { state } }: GridRenderCellParams<Pickup>) {
      return <PickupStateChip pickupState={state} />;
    },
  },
  {
    field: "pickup_details.freighter",
    headerName: "Freighter",
    width: 80,
    filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    valueGetter: (params: GridValueGetterParams<Pickup, Pickup>) => {
      return params.row.freighter?.name;
    },
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
  },
];

export default function LogisticsPickups() {
  const { pickups, count, confirmPickup, cancelPickup } = useLogisticsPickups();

  const searchRef = useRef<HTMLInputElement | null>(null);

  const pageState = useServerSideDataGridParams(pickups);

  const getDetailPanelContent = useCallback(
    ({ row: pickup }: GridRowParams<Pickup>) => (
      <LogisticsPickupRow pickup={pickup} confirmPickup={confirmPickup} cancelPickup={cancelPickup} />
    ),
    [cancelPickup, confirmPickup],
  );

  const onTabChange = useCallback(() => {
    if (searchRef.current) {
      searchRef.current.value = "";
    }
  }, []);

  return (
    <Box>
      <Breadcrumbs data={[{ label: "Logistics", to: "/logistics" }, { label: "Pickups" }]}></Breadcrumbs>
      <Box mt={4}>
        <Typography variant="h4">Pickups</Typography>
        <Typography mt={0.5}>
          View details for the pickups that have been scheduled in the past, need approval, or are confirmed and
          upcoming.
        </Typography>
      </Box>
      <LogisticsPickupTabs onTabChange={onTabChange} />
      <Box>
        <Grid container spacing={2} sx={{ padding: "5px", paddingBottom: "60px" }}>
          <Grid item xs={6}>
            <Button component={RouterLink} variant="contained" to="/logistics/schedule-pickup" startIcon={<Add />}>
              schedule a pickup
            </Button>
          </Grid>
        </Grid>
      </Box>

      <DataGridPro
        autoHeight
        sx={{ background: "background.paper" }}
        rows={pickups}
        columns={columns}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => "auto"}
        {...pageState}
        pageSize={pageState.pageSize || 25}
        rowsPerPageOptions={[25, 50, 100]}
        filterMode="server"
        rowCount={count}
        components={{
          Toolbar: CustomToolbar,
        }}
        initialState={{
          pinnedColumns: { right: [GRID_DETAIL_PANEL_TOGGLE_FIELD] },
        }}
      />
    </Box>
  );
}
