import { useEventCallback } from "@mui/material";
import { useEffect } from "react";

export function useEventListener(eventName: string, handler: (event: Event) => void) {
  const ecb = useEventCallback(handler);
  useEffect(() => {
    window.addEventListener(eventName, ecb);
    return () => {
      window.removeEventListener(eventName, ecb);
    };
  }, [eventName, ecb]);
}
