import { GridSelectionModel } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { Inventory, InventoryBulkEditProps } from "types/inventory";
import { Tag } from "types/tag";
import { useAlert } from "./useAlert";
import { useConfirmation } from "./useConfirmation";

export type useInventoryType = ReturnType<typeof useInventory>;

export default function useInventory(defaultPageSize?: number) {
  const navigate = useNavigate();
  const location = useLocation();
  const { confirm } = useConfirmation();
  const params = useParams();
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const [enrolledInventories, setEnrolledInventories] = useState<Inventory[]>([]);
  const [filterTags, setFilterTags] = useState<Tag[]>([]);
  const [inventory, setInventory] = useState<Inventory>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [selectedInventoryIds, setSelectedInventoryIds] = useState<GridSelectionModel>([]);
  const [selectedInventories, setSelectedInventories] = useState<Inventory[]>([]);
  const { search } = useLocation();
  const { alertError } = useAlert();
  const [isLoading, setLoading] = useState(false);
  const [printConfirmDialogOpen, setPrintConfirmDialogOpen] = useState(false);

  useEffect(() => {
    if (!params.inventoryId && location.pathname !== "/enrolled") {
      getInventories();
    }
    if (location.pathname === "/enrolled") {
      getEnrolledInventories();
    }
  }, [params.pickupId, search, filterTags, inventory]);

  useEffect(() => {
    if (params.inventoryId) {
      getInventory();
    }
  }, [params.inventoryId]);

  useEffect(() => {
    const allInv = inventories.concat(enrolledInventories);
    setSelectedInventories(allInv.filter(({ id }) => selectedInventoryIds.includes(id)));
  }, [selectedInventoryIds, inventories]);

  const handleClose = () => {
    setPrintConfirmDialogOpen(false);
  };

  const createInventory = (props: any) => {
    const inventories = props.inventories.map((inventory: any) => {
      delete inventory.id;
      return inventory;
    });
    setLoading(true);
    api
      .post(`/pickups/${params.pickupId}/inventories`, {
        inventories,
      })
      .then(() => {
        navigate(`/pickups/${params.pickupId}`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 404) {
          alertError("Please select a valid inventory template");
        }
      });
  };

  const updateInventory = (props: Inventory, skipActionParam?: string[]) => {
    const {
      color,
      condition,
      manufacturer,
      serialNumber,
      warehouseLocation,
      warehouseId,
      model,
      modelOrder,
      ram,
      cpu,
      hardDrive,
      sku,
      description,
      comments,
      securityLevel,
      batteryCount,
      batteryHealth,
      trackpadIsWorking,
      keyboardIsWorking,
      batteryIsWorking,
      lcdDisplayIsWorking,
      trackpadFunctionality,
      keyboardFunctionality,
      screenFunctionality,
      batteryFunctionality,
      cameraFunctionality,
      microphoneFunctionality,
      speakerFunctionality,
      portsFunctionality,
      powerFunctionality,
      logicFunctionality,
      resalePhotos,
      uuid,
      tags,
      year,
      deviceType,
      assetTag,
      gpu,
      enrolledStatus,
      enrolledOrganization,
      hardDriveSizeInGb,
      drive1ErasureStatus,
      resalePhotosOrder,
    } = props;
    return api
      .put(`/pickups/${params.pickupId}/inventories/${uuid}`, {
        inventory: {
          color,
          condition,
          manufacturer,
          serialNumber,
          warehouseLocation,
          warehouseId,
          model,
          modelOrder,
          ram,
          cpu,
          hardDrive,
          sku,
          description,
          comments,
          securityLevel,
          batteryCount,
          batteryHealth,
          trackpadIsWorking,
          keyboardIsWorking,
          batteryIsWorking,
          lcdDisplayIsWorking,
          trackpadFunctionality,
          keyboardFunctionality,
          screenFunctionality,
          batteryFunctionality,
          cameraFunctionality,
          microphoneFunctionality,
          speakerFunctionality,
          portsFunctionality,
          powerFunctionality,
          logicFunctionality,
          resalePhotos,
          tagNames: tags?.map(({ name }) => name),
          year,
          deviceType,
          assetTag,
          gpu,
          enrolledStatus,
          enrolledOrganization,
          hardDriveSizeInGb,
          drive1ErasureStatus,
          resalePhotosOrder,
          ...(skipActionParam || []).reduce((acc, c) => ({ ...acc, [c]: true }), {}),
        },
      })
      .catch((error) => {
        if (error.response.status === 422) {
          confirm({
            handler: () => {
              updateInventory(props, [...(skipActionParam || []), error.response.data.errors.skip_param]);
            },
            message: error.response.data.errors.message,
            context: error.response.data.errors.skip_param,
          });
        }
        throw error;
      });
  };

  const getInventory = () => {
    api
      .get(`inventories/${params.inventoryId}`)
      .then(({ data }) => {
        setInventory(data);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          alertError(`Can't find inventory with id or label ${params.inventoryId}`);
        }
      });
  };

  const getInventoryById = async (inventoryId: string) => {
    return await api
      .get(`inventories/${inventoryId}`)
      .then(({ data }) => {
        setInventory(data);
        return data;
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          alertError(`Can't find inventory with id or label ${inventoryId}`);
        }
      });
  };

  const getInventories = () => {
    const searchHash = new URLSearchParams(location.search);
    if (!searchHash.get("page[size]") && defaultPageSize) {
      searchHash.append("page[size]", String(defaultPageSize));
    }
    filterTags.forEach(({ name }) => {
      searchHash.append("tag_names[]", name);
    });
    setLoading(true);
    if (params.pickupId) {
      api.get(`/pickups/${params.pickupId}/inventories?${searchHash.toString()}`).then(
        ({
          data: {
            inventories,
            meta: { totalPages, count },
          },
        }) => {
          setInventories(inventories);
          setTotalPages(totalPages);
          setCount(count);
          setLoading(false);
        },
      );
    } else {
      api.get(`/inventories?${searchHash.toString()}`).then(
        ({
          data: {
            inventories,
            meta: { totalPages, count },
          },
        }) => {
          setInventories(inventories);
          setTotalPages(totalPages);
          setCount(count);
          setLoading(false);
        },
      );
    }
  };

  const getEnrolledInventories = () => {
    const searchHash = new URLSearchParams(location.search);
    if (!searchHash.get("page[size]") && defaultPageSize) {
      searchHash.append("page[size]", String(defaultPageSize));
    }
    api.get(`/enrolled_inventories?${searchHash.toString()}`).then(
      ({
        data: {
          inventories,
          meta: { totalPages, count },
        },
      }) => {
        setEnrolledInventories(inventories);
        setTotalPages(totalPages);
        setCount(count);
      },
    );
  };

  const deleteInventories = (inventoriesToDelete: Inventory[]) => {
    setLoading(true);
    return api.delete("inventories/bulk", { data: { inventories: inventoriesToDelete } }).then(() => {
      setLoading(false);
    });
  };

  const updateEnrolledInventories = (inventories: Inventory[], enrolledStatus: string) => {
    setLoading(true);
    const payload = inventories.map((inventory) => {
      return { id: inventory.id, enrolledStatus };
    });
    return api.put("inventories/bulk/edit", { inventories: payload }).then(() => {
      setLoading(false);
    });
  };

  const updatedInventories = (inventoriesToUpdate: InventoryBulkEditProps[]) => {
    return api
      .put("inventories/bulk/edit", { inventories: inventoriesToUpdate })
      .then(() => {
        const changedInventories = [...inventories];
        inventoriesToUpdate.forEach((invToUpdate) => {
          const invIndex = changedInventories.findIndex((inv) => inv.id === invToUpdate.id);
          changedInventories[invIndex] = { ...changedInventories[invIndex], ...invToUpdate };
        });
        setInventories(changedInventories);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          confirm({
            handler: () => {
              // updateInventory(props, [...(skipActionParam||[]), error.response.data.errors.skip_param]);
            },
            message: error.response.data.errors.message,
          });
        }
        throw error;
      });
  };

  const removeTag = useCallback(
    async (tag: Tag, inventoryId) => {
      const { data } = await api.delete(`/inventories/${inventoryId}/tags/${tag.id}`);
      setInventory(data);
    },
    [inventory],
  );

  const markInventoryPrinted = useCallback(() => {
    if (inventory) {
      updateInventory({
        ...inventory,
        id: inventory.id,
        printedAt: new Date().toUTCString(),
        label: `${inventory.pickup.id} - ${inventory.lineId}`,
      }).then(() => setPrintConfirmDialogOpen(false));
    }
  }, [inventory]);

  const markInventoriesPrinted = useCallback(() => {
    updatedInventories(
      selectedInventories.map((inv) => ({
        id: inv.id,
        printedAt: new Date().toUTCString(),
        label: `${inv.pickup.id} - ${inv.lineId}`,
      })),
    ).then(() => {
      setSelectedInventories([]);
      setSelectedInventoryIds([]);
      getInventories();
      setPrintConfirmDialogOpen(false);
    });
  }, [selectedInventories]);

  return {
    inventories,
    inventory,
    setInventory,
    totalPages,
    count,
    createInventory,
    updateInventory,
    getInventory,
    getInventoryById,
    getInventories,
    selectedInventoryIds,
    setSelectedInventoryIds,
    selectedInventories,
    setSelectedInventories,
    deleteInventories,
    filterTags,
    setFilterTags,
    updatedInventories,
    markInventoryPrinted,
    markInventoriesPrinted,
    removeTag,
    isLoading,
    printConfirmDialogOpen,
    setPrintConfirmDialogOpen,
    handleClose,
    setInventories,
    enrolledInventories,
    getEnrolledInventories,
    updateEnrolledInventories,
  };
}
