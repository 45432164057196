import { Box, Button, Chip, Grid, IconButton, LinearProgress, Tooltip, Typography } from "@mui/material";
import { FileUpload as FileUploadIcon } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { isValidElement, ReactNode, useCallback, useRef } from "react";
import Breadcrumbs from "components/common/Breadcrumbs";
import {
  DataGridPro,
  getGridDateOperators,
  getGridStringOperators,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridColDef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridValueGetterParams,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { userHasPermission } from "utils/userPermissions";
import useCurrentUser from "hooks/useCurrentUser";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssetRetrievalTabs from "./AssetRetrievalTabs";
import AssetRetrievalRow from "./AssetRetrievalRow";
import useAssetRetrievals from "hooks/useAssetRetrievals";
import { GridCellPopout } from "./GridCellPopout";
import useAssetRetrievalShipments from "hooks/useAssetRetrievalShipments";
import { AssetRetrieval } from "types/assetRetrieval";
import { useConfirmation } from "hooks/useConfirmation";
import { getCurrentEasternTimezoneName } from "../../utils/helpers";

const CustomDetailPanelToggle = (props: Pick<GridRenderCellParams, "id" | "value">) => {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);
  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton size="small" tabIndex={-1} disabled={!hasDetail} aria-label={isExpanded ? "Close" : "Open"}>
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
};

function renderGridCellPopout(params: GridCellParams) {
  return <GridCellPopout value={params.value ? params.value : ""} width={params.colDef.computedWidth} />;
}

const withPopout = (customRenderer: (params: GridCellParams) => ReactNode) => (params: GridCellParams) =>
  renderGridCellPopout({
    ...params,
    value: customRenderer(params),
  });

const MarkAsCompleteButton = ({
  assetRetrieval,
  complete,
}: {
  assetRetrieval: AssetRetrieval;
  complete: (a: AssetRetrieval) => void;
}) => {
  const { confirm } = useConfirmation();
  const disableComplete =
    assetRetrieval.outboundAssetRetrievalShipment?.trackingNumber === "" ||
    assetRetrieval.inboundAssetRetrievalShipment?.trackingNumber === "" ||
    assetRetrieval.outboundAssetRetrievalShipment?.trackingNumber === undefined ||
    assetRetrieval.inboundAssetRetrievalShipment?.trackingNumber === undefined;
  return (
    <Tooltip title={disableComplete ? "Please enter in the tracking numbers before completing the order" : ""}>
      <span>
        <IconButton
          disabled={disableComplete}
          onClick={() => {
            confirm({
              handler: () => {
                complete(assetRetrieval);
              },
              title: "Mark as completed!",
              message: "Are you sure you want to mark this as completed?",
            });
          }}
        >
          <CheckCircleIcon sx={{ color: "#4CAF50" }} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

const CancelButton = ({
  assetRetrieval,
  cancel,
}: {
  assetRetrieval: AssetRetrieval;
  cancel: (a: AssetRetrieval) => void;
}) => {
  const { confirm } = useConfirmation();
  const isDisabled =
    assetRetrieval.state === "order_cancelled" ||
    assetRetrieval.state === "order_completed" ||
    assetRetrieval.state === "order_out_to_final_destination";

  return (
    <Tooltip title="Cancel the order and any shipping labels that were not yet scanned">
      <span>
        <IconButton
          disabled={isDisabled}
          onClick={() => {
            confirm({
              handler: () => {
                cancel(assetRetrieval);
              },
              title: "Cancel!",
              message:
                "Are you sure you want to cancel? This will also cancel any shipping labels that were not yet scanned.",
            });
          }}
        >
          <CancelIcon color={isDisabled ? "disabled" : "primary"} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

const ActionsButtons = ({
  assetRetrieval,
  complete,
  cancel,
}: {
  assetRetrieval: AssetRetrieval;
  complete: (a: AssetRetrieval) => void;
  cancel: (a: AssetRetrieval) => void;
}) => {
  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <CancelButton assetRetrieval={assetRetrieval} cancel={cancel} />
      <MarkAsCompleteButton assetRetrieval={assetRetrieval} complete={complete} />
    </Box>
  );
};

export default function AssetRetrievalsGrid() {
  const { currentUserPermissions } = useCurrentUser();
  const {
    assetRetrievals,
    assetRetrievalStateOptions,
    count,
    bulkCreateAssetRetrievalOrders,
    fetchAssetRetrievals,
    complete,
    cancel,
  } = useAssetRetrievals();
  const { createAssetRetrievalShipments, updateAssetRetrievalShipments } = useAssetRetrievalShipments();
  const searchRef = useRef<any>();

  const pageState = useServerSideDataGridParams(assetRetrievals);

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      width: 100,
      renderCell(params) {
        if (params.row.state !== "order_placed")
          return <CancelButton assetRetrieval={params.row as AssetRetrieval} cancel={cancel} />;
        return <ActionsButtons assetRetrieval={params.row as AssetRetrieval} complete={complete} cancel={cancel} />;
      },
    },
    {
      field: "orderNumber",
      headerName: "Order number",
      width: 150,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => ["contains", "isAnyOf"].includes(op.value)),
    },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      width: 150,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => ["contains", "isAnyOf"].includes(op.value)),
    },
    {
      field: "createdAt",
      headerName: "Date",
      sortable: false,
      type: "date",
      width: 150,
      filterOperators: getGridDateOperators().filter((op) => ["is"].includes(op.value)),
      valueGetter: (params: GridValueGetterParams) => {
        return Intl.DateTimeFormat("en-US", { timeZone: "America/New_York" }).format(Date.parse(params.row.createdAt));
      },
    },
    {
      field: "createdAtTime",
      renderHeader: () => {
        return `Time (${getCurrentEasternTimezoneName()})`;
      },
      filterable: false,
      sortable: false,
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          timeZone: "America/New_York",
        }).format(Date.parse(params.row.createdAt));
      },
    },
    {
      field: "organizations.name",
      headerName: "Company",
      sortable: false,
      flex: 1,
      filterOperators: getGridStringOperators().filter((op) => ["contains", "isAnyOf"].includes(op.value)),
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.organization.name;
      },
    },
    {
      field: "employeeAddressState",
      headerName: "To: State",
      sortable: false,
      flex: 1,
      filterOperators: getGridStringOperators().filter((op) => ["contains", "isAnyOf"].includes(op.value)),
    },
    {
      field: "locations.state",
      headerName: "Return: State",
      sortable: false,
      flex: 1,
      filterOperators: getGridStringOperators().filter((op) => ["contains", "isAnyOf"].includes(op.value)),
      renderCell: (params) => {
        return <Typography>{params.row.location?.state}</Typography>;
      },
    },
    {
      field: "state",
      headerName: "Status",
      sortable: false,
      flex: 2,
      type: "singleSelect",
      valueOptions: assetRetrievalStateOptions,
      renderCell({ row }: GridRenderCellParams<any>) {
        return (
          <Chip
            label={row.state
              .split("_")
              .map((state: string) => state.toUpperCase())
              .join(" ")}
          />
        );
      },
    },
    {
      field: "virtualPickup",
      headerName: "Direct to Revivn",
      filterable: true,
      sortable: false,
      type: "singleSelect",
      valueOptions: [
        { value: true, label: "True" },
        { value: false, label: "False" },
      ],
      renderCell: (params) => {
        return <Typography>{params.row.virtualPickup ? "Yes" : ""}</Typography>;
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
    },
  ];

  const getDetailPanelContent = useCallback(
    ({ row }: GridRowParams) => (
      <AssetRetrievalRow
        row={row}
        submit={(assetRetrievalsUuid: string, params: { type: string; trackingNumber: string }) => {
          createAssetRetrievalShipments(assetRetrievalsUuid, params).then(() => {
            fetchAssetRetrievals();
          });
        }}
        update={(
          assetRetrievalsUuid: string,
          shipmentUuid: string,
          params: { type: string; trackingNumber: string },
        ) => {
          updateAssetRetrievalShipments(assetRetrievalsUuid, shipmentUuid, params).then(() => {
            fetchAssetRetrievals();
          });
        }}
      />
    ),
    [],
  );

  const onTabChange = useCallback(() => {
    if (searchRef.current) {
      searchRef.current.value = "";
    }
  }, []);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const handleClick = (event: any) => {
    const file = event.target.files[0];
    bulkCreateAssetRetrievalOrders(file);
  };
  return (
    <Box>
      <Breadcrumbs data={[{ label: "Outbound Shipping" }]}></Breadcrumbs>
      <Box mt={4}>
        <Typography variant="h4">Outbound Shipping</Typography>
        <Typography mt={0.5}>Send boxes to customers for remote laptop retrievals or remote pickups.</Typography>
      </Box>
      <AssetRetrievalTabs onTabChange={onTabChange} />

      {userHasPermission("admin", currentUserPermissions) && (
        <Grid container sx={{ padding: "0 0 20px 0" }} spacing={2} direction="row">
          <Grid item>
            <Button variant="contained" component="label" color="primary">
              <FileUploadIcon /> Bulk Upload
              <input id="upload-image" hidden accept="csv/*" type="file" onChange={handleClick} />
            </Button>
          </Grid>
        </Grid>
      )}

      <DataGridPro
        autoHeight
        sx={{ background: "background.paper" }}
        rows={assetRetrievals}
        getRowId={({ uuid }) => uuid}
        {...pageState}
        columns={columns}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => "auto"}
        rowsPerPageOptions={[5, 25, 50, 100]}
        filterMode="server"
        rowCount={count}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: CustomToolbar,
        }}
      />
    </Box>
  );
}
