import {
  DataGridPro,
  GridColDef,
  getGridStringOperators,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiRef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { Box, styled, Paper, LinearProgress, Stack } from "@mui/material";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import { PartnerInventory } from "types/partnerInventory";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  height: "80vh",
  border: "none",
  backgroundColor: "background.paper",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.mode == "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
  },
}));

export default function PartnerInventoryDataGrid({
  partnerInventories,
  loading,
  count,
}: {
  partnerInventories: PartnerInventory[];
  loading: boolean;
  count: number;
}) {
  const defaultPageSize = 25;
  const apiRef = useGridApiRef();

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "Serial Number",
      width: 200,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      width: 200,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "deviceType",
      headerName: "Device Type",
      width: 200,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "enrolledStatus",
      headerName: "Enrolled Status",
      width: 350,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "endOfLifeCondition",
      headerName: "EOL Condition",
      width: 200,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "netResalePriceInCents",
      headerName: "Net Price",
      width: 150,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<PartnerInventory>) => {
        return <div>{params.row.resalePriceInCents ? params.row.resalePriceInCents / 100.0 : ""}</div>;
      },
    },
    {
      field: "year",
      headerName: "Year",
      width: 200,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "model",
      headerName: "Model",
      width: 200,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "assetTag",
      headerName: "Asset Tag",
      width: 200,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "ram",
      headerName: "RAM",
      width: 200,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "cpu",
      headerName: "CPU",
      width: 200,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "hard_drive",
      headerName: "Hard Drive",
      width: 200,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
  ];

  const pageState = useServerSideDataGridParams(partnerInventories);

  return (
    <Box sx={{ width: "100%", height: "80vh" }}>
      <Paper elevation={2} sx={{ mt: 2, p: 1 }}>
        <StyledDataGrid
          rows={partnerInventories}
          columns={columns}
          {...pageState}
          pageSize={pageState.pageSize || defaultPageSize}
          rowsPerPageOptions={[5, 10, 25, 100]}
          rowCount={count}
          filterMode="server"
          apiRef={apiRef}
          checkboxSelection={false}
          checkboxSelectionVisibleOnly={false}
          loading={loading}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: CustomToolbar,
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No inventory
              </Stack>
            ),
          }}
          componentsProps={{ filterPanel: { sx: { minHeight: "200px" } } }}
        />
      </Paper>
    </Box>
  );
}
