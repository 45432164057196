import { Box, FormControl, Button, MenuItem } from "@mui/material";
import { AppSelect, InputBase, InputLabel } from "components/common/Input";
import { useFormik } from "formik";
import useLogisticsPartners from "hooks/useLogisticsPartners";

import { useNavigate, useParams } from "react-router-dom";
import { LogisticsPartner } from "types/logisticsPartner";
import { sleep } from "utils/sleep";

type PartnerFormProps = {
  logisticsPartner?: LogisticsPartner;
};

export default function PartnerForm({ logisticsPartner }: PartnerFormProps) {
  const navigate = useNavigate();
  const { createLogisticsPartner, updateLogisticsPartner } = useLogisticsPartners();
  const params = useParams();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: logisticsPartner?.name || "",
      type: logisticsPartner?.type || "",
      googleDriveLink: logisticsPartner?.googleDriveLink || "",
    },

    onSubmit: async (values) => {
      try {
        if (params.uuid) {
          await updateLogisticsPartner(values);
          navigate(`/logistics/partners/${logisticsPartner?.uuid}`);
        } else {
          await createLogisticsPartner(values);
          await sleep(300);
          navigate(`/logistics/partners`);
        }
      } catch {
        // alertError("There was an error, please try again");
      }
    },
  });

  return (
    <div>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{}} noValidate autoComplete="off">
        <Box
          sx={{
            p: 4,
            background: "background.paper",
            borderRadius: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="name">
                Name
              </InputLabel>
              <InputBase id="name" {...formik.getFieldProps("name")} />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="name">
                Partner Type:
              </InputLabel>
              <AppSelect defaultValue="" id="type" {...formik.getFieldProps("type")}>
                {["Agent", "Itad", "Freighter"].map((partnerType) => {
                  return (
                    <MenuItem key={partnerType} value={partnerType}>
                      {partnerType}
                    </MenuItem>
                  );
                })}
              </AppSelect>
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="googleDriveLink">
                Google Drive Link
              </InputLabel>
              <InputBase id="googleDriveLink" {...formik.getFieldProps("googleDriveLink")} />
            </FormControl>
          </Box>
        </Box>
        <Box mt={4} sx={{ display: "flex", justifyContent: "end" }}>
          <Button color="primary" variant="contained" type="submit">
            SAVE
          </Button>
        </Box>
      </Box>
    </div>
  );
}
