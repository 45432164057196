import { useEventListener } from "hooks/useEventListener";
import { IndexedInventory } from "types/inventory";
import { useConfirmation } from "hooks/useConfirmation";
import { pluralize } from "utils/helpers";
import { api } from "services/api.service";
import { useAlert } from "hooks/useAlert";
import { dispatchBulkActionComplete } from "components/DataGrid/behaviors/withBulkActions";

export const BulkDeleteAction = () => {
  const { confirm } = useConfirmation();
  const { alertSuccess, alertError } = useAlert();

  useEventListener("bulkDelete", (event) => {
    if (event instanceof CustomEvent) {
      const { selectedRows } = event.detail as { selectedRows: IndexedInventory[] };
      confirm({
        handler: () => {
          api
            .delete("inventories/bulk", { data: { inventories: selectedRows } })
            .then(() => {
              alertSuccess(
                `${pluralize(selectedRows.length, "inventory", "inventories")} deleted.  It may take a few seconds for the changes to reflect.`,
              );
              dispatchBulkActionComplete();
            })
            .catch((error) => {
              alertError(`Failed to delete inventories: Status ${error.statusText}`);
            });
        },
        message: `Are you sure you want to delete ${pluralize(selectedRows.length, "inventory", "inventories")}?`,
      });
    }
  });

  return null;
};
