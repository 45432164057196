import { GridColTypeDef, ValueOptions } from "@mui/x-data-grid-pro";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function allOptionsAreObjects(options: ValueOptions[]): options is { value: any; label: string }[] {
  return options.every((o) => typeof o === "object" && "value" in o && "label" in o);
}

export const singleSelectColDef = function (options: ValueOptions[]): GridColTypeDef {
  return {
    type: "singleSelect",
    valueGetter: ({ value }) => {
      if (allOptionsAreObjects(options)) return options.find((option) => option.value === value)?.label ?? value;
      return value;
    },
    valueOptions: options,
  };
};
