import {
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { readableDate } from "utils/readableDate";
import { Discount as DiscountIcon } from "@mui/icons-material";

function ActivityTable(transactions: any) {
  return (
    <div>
      <TableContainer
        sx={{
          mt: 2,
        }}
      >
        <Table sx={{ ".MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th": { border: 0 } }}>
          <TableHead sx={{ ".MuiTableCell-root": { color: "text.disabled", borderBottom: 0 } }}>
            <TableRow>
              <TableCell>DATE POSTED</TableCell>
              <TableCell>INTERNAL NOTES</TableCell>
              <TableCell>DESCRIPTION</TableCell>
              <TableCell>AMOUNT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions &&
              transactions.transactions.map((transaction: any) => {
                return (
                  <>
                    <TableRow key={transaction.id}>
                      <TableCell>{readableDate(transaction.publishedAt)}</TableCell>
                      <TableCell>
                        <Typography variant="body2">{transaction.internalNotes}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{transaction.description}</Typography>
                      </TableCell>
                      <TableCell>
                        {transaction.type == "::EarningsCredit" && (
                          <Typography variant="body2" color="success.main">
                            +${transaction.amountInCents / 100.0}
                          </Typography>
                        )}
                        {transaction.type == "::EarningsDebit" && !transaction.waived && (
                          <Typography variant="body2" color="error.main">
                            -${transaction.amountInCents / 100.0}
                          </Typography>
                        )}
                        {transaction.type == "::EarningsDebit" && transaction.waived && (
                          <Typography variant="body2" color="secondary.main">
                            -${transaction.displayAmountInCents / 100.0}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {transaction.type == "::EarningsDebit" && transaction.waived && (
                          <Chip
                            style={{ color: "background.paper", fontWeight: 600, marginLeft: "10px", fontSize: "12px" }}
                            color="secondary"
                            label="FEES WAIVED"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ActivityTable;
