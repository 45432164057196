import { createTheme, ThemeProvider } from "@mui/material";
import { AlertProvider } from "hooks/useAlert";
import useAuth from "hooks/useAuth";
import { ConfirmationProvider } from "hooks/useConfirmation";
import LoggedInRouter from "LoggedInRouter";
import { LoggedOutRouter } from "LoggedOutRouter";
import { createContext, useMemo, useState } from "react";
import { getThemeOptions } from "utils/theme";

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export function App() {
  const { loggedIn } = useAuth();
  const [mode, setMode] = useState((localStorage.getItem("theme") as "light" | "dark") || "light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        const newMode = mode === "light" ? "dark" : "light";
        setMode(newMode);
        localStorage.setItem("theme", newMode);
      },
    }),
    [mode],
  );

  const theme = useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode,
          },
          typography: {
            fontFamily: "Verdana",
            h6: {
              fontSize: 18,
              fontWeightMedium: 500,
            },
            body1: {
              fontSize: 14,
              fontWeightRegular: 400,
            },
            body2: {
              fontSize: 14,
              fontWeightLight: 700,
            },
          },
        },
        getThemeOptions(mode),
      ),
    [mode],
  );

  const route = loggedIn ? <LoggedInRouter /> : <LoggedOutRouter />;

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <AlertProvider>
          <ConfirmationProvider>{route}</ConfirmationProvider>
        </AlertProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
