import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  getGridStringOperators,
  getGridSingleSelectOperators,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { labelTooltip } from "../../constants/inventory_tooltips";
import useInventory from "hooks/useInventory";
import { Link } from "react-router-dom";
import { Inventory } from "types/inventory";
import { Box, styled, LinearProgress, Stack } from "@mui/material";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import { deviceTypes } from "constants/device_types";
import EnrolledInventoriesDataGridActions from "./EnrolledInventoriesDataGridActions";
import { readableDate } from "utils/readableDate";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  height: "80vh",
  border: "none",
  backgroundColor: "background.paper",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.mode == "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
  },
}));

export default function EnrolledInventoriesDataGrid() {
  const defaultPageSize = 200;
  const useInventoriesState = useInventory(defaultPageSize);
  const { enrolledInventories, count, selectedInventoryIds, setSelectedInventoryIds, isLoading, selectedInventories } =
    useInventoriesState;

  const apiRef = useGridApiRef();

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "label",
      headerName: "Label",
      description: labelTooltip,
      width: 125,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
      renderCell: (params: GridRenderCellParams<Inventory>) => {
        if (params.row.pickup) {
          return <Link to={`/pickups/${params.row.pickup.uuid}/inventories/${params.row.id}`}>{params.row.label}</Link>;
        } else {
          return <div>{params.row.label}</div>;
        }
      },
    },
    {
      field: "pickup.scheduled_at",
      headerName: "Pickup Date",
      sortable: false,
      filterable: false,
      width: 175,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
      renderCell: (params: GridRenderCellParams<Inventory>) => {
        if (params.row.pickup) {
          return <div>{readableDate(params.row.pickup.scheduledAt)}</div>;
        }
      },
    },
    {
      field: "warehouses.name",
      headerName: "Warehouse",
      width: 125,
      filterOperators: getGridSingleSelectOperators().filter((op) => op.value === "is"),
      valueOptions: [
        { label: "NY", value: "New York" },
        { label: "SF", value: "San Francisco" },
        { label: "3PL", value: "3PL" },
      ],
      renderCell: (params: GridRenderCellParams<Inventory>) => {
        const warehouse_acronym_map = {
          "New York": "NY",
          "San Francisco": "SF",
          "3PL": "3PL",
        };
        const warehouse_name: keyof typeof warehouse_acronym_map = params.row.warehouse
          ? params.row.warehouse.name
          : "";
        return warehouse_acronym_map[warehouse_name] ? warehouse_acronym_map[warehouse_name] : "";
      },
    },
    {
      field: "warehouseLocation",
      headerName: "Location",
      width: 250,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
      editable: true,
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      width: 150,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "deviceType",
      headerName: "Device Type",
      width: 200,
      filterOperators: getGridSingleSelectOperators().filter((op) => op.value === "is"),
      valueOptions: deviceTypes,
    },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      width: 250,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
      editable: true,
    },
  ];

  const pageState = useServerSideDataGridParams(enrolledInventories);

  return (
    <Box sx={{ width: "100%", height: "80vh" }}>
      {selectedInventories.length ? (
        <EnrolledInventoriesDataGridActions useInventoriesState={useInventoriesState} />
      ) : null}

      <StyledDataGrid
        rows={enrolledInventories}
        columns={columns}
        checkboxSelection
        {...pageState}
        pageSize={pageState.pageSize || defaultPageSize}
        rowsPerPageOptions={[5, 10, 25, 100, 200]}
        rowCount={count}
        selectionModel={selectedInventoryIds}
        onSelectionModelChange={setSelectedInventoryIds}
        filterMode="server"
        apiRef={apiRef}
        loading={isLoading}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: CustomToolbar,
        }}
        componentsProps={{ filterPanel: { sx: { minHeight: "200px" } } }}
      />
    </Box>
  );
}
