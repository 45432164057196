import { format } from "date-fns";
import { AxiosError } from "axios";

export function formatDate(dateString: string) {
  if (!dateString) return;
  const date = new Date(dateString.substring(0, 10)).toISOString().slice(0, -1);

  return format(new Date(date), "MMMM dd, yyyy"); // ex: April 27, 2022;
}

export function parseTimeToAmPm(timestamp: string) {
  if (!timestamp) return;
  const date = new Date(timestamp);

  return format(date, "hh:mm a"); // ex: 04:12 PM
}

export function numberWithCommas(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatCentsToDollar(cents: number) {
  const dollarNumber = cents / 100;
  return dollarNumber.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export function getCurrentEasternTimezoneName(): string {
  const intlFormat = new Intl.DateTimeFormat("en-US", { timeZone: "America/New_York", timeZoneName: "short" });
  return intlFormat.formatToParts(new Date()).find((x) => x.type === "timeZoneName")!.value;
}

const pluralRules = new Intl.PluralRules("en-US");

export function pluralize(count: number, singular: string, plural: string) {
  const grammaticalNumber = pluralRules.select(count);
  switch (grammaticalNumber) {
    case "one":
      return count + " " + singular;
    case "other":
      return count + " " + plural;
    default:
      throw new Error("Unknown: " + grammaticalNumber);
  }
}

export function isAxiosError(error: unknown): error is AxiosError {
  return typeof error === "object" && error !== null && "isAxiosError" in error;
}
