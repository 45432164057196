import ApplicationBreadCrumbs from "components/ApplicationBreadCrumbs";
import BreadCrumbsContainer from "contexts/breadcrumbContext";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { Route, Routes } from "react-router-dom";
import PickupsDataGrid from "../components/Pickups/PickupsDataGrid";
import PickupShowPage from "./PickupShowPage";

function PickupsBody() {
  const { crumbs, crumb } = useBreadcrumbs({
    name: "Pickups",
    path: "/pickups",
  });

  return (
    <>
      <ApplicationBreadCrumbs sx={{ my: 0, mx: 0 }} crumbs={crumbs} />
      <Routes>
        <Route path=":pickupId/*" element={crumb && <PickupShowPage />} />
        <Route index element={<PickupsDataGrid />} />
      </Routes>
    </>
  );
}

export default function Pickups() {
  return (
    <BreadCrumbsContainer>
      <PickupsBody />
    </BreadCrumbsContainer>
  );
}
