import { Box, Button, Chip, LinearProgress, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import usePickup from "../hooks/usePickup";
import { useZxing } from "react-zxing";
import { useMediaDevices } from "react-media-devices";
import { useNavigate } from "react-router-dom";
import { AppSelect, SearchInput } from "../components/common/Input";
import useAssetRetrievals from "../hooks/useAssetRetrievals";
import {
  DataGridPro,
  getGridDateOperators,
  getGridStringOperators,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import useServerSideDataGridParams from "../hooks/useServerSideDataGridParams";
import { getCurrentEasternTimezoneName } from "../utils/helpers";
import { useAlert } from "hooks/useAlert";

export default function VirtualPickup() {
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>();
  const [trackingNumber, setTrackingNumber] = useState<string>("");
  const { assetRetrievals, count } = useAssetRetrievals();
  const pageState = useServerSideDataGridParams(assetRetrievals);
  const navigate = useNavigate();
  const { fetchPickupByShippingLabel } = usePickup();
  const { alertError } = useAlert();
  const { devices } = useMediaDevices({
    constraints: {
      video: {
        facingMode: "environment",
      },
    },
  });
  const deviceId = devices?.[0]?.deviceId;

  const navigateToPickup = () => {
    if (!trackingNumber) return;
    fetchPickupByShippingLabel(trackingNumber)
      .then(({ data }) => {
        navigate(
          `/pickups/${data.uuid}/inventories/new-virtual-pickup?tracking_number=${data.trackingNumber}&order_number=${data.orderNumber}`,
        );
      })
      .catch(({ response: { data } }) => {
        alertError(data.errors.virtual_pickup || "Something went wrong, please try again!");
      });
  };
  const { ref } = useZxing({
    deviceId: selectedDeviceId ?? deviceId,
    onDecodeResult(result) {
      setTrackingNumber(result.getText());
      navigateToPickup();
    },
  });

  const columns: GridColDef[] = [
    {
      field: "orderNumber",
      headerName: "Order number",
      width: 150,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => ["contains", "isAnyOf"].includes(op.value)),
    },
    {
      field: "createdAt",
      headerName: "Date",
      sortable: false,
      type: "date",
      width: 150,
      filterOperators: getGridDateOperators().filter((op) => ["is"].includes(op.value)),
      valueGetter: (params: GridValueGetterParams) => {
        return Intl.DateTimeFormat("en-US", { timeZone: "America/New_York" }).format(Date.parse(params.row.createdAt));
      },
    },
    {
      field: "createdAtTime",
      renderHeader: () => {
        return `Time (${getCurrentEasternTimezoneName()})`;
      },
      filterable: false,
      sortable: false,
      width: 150,
      valueGetter: (params: GridValueGetterParams) => {
        return new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          timeZone: "America/New_York",
        }).format(Date.parse(params.row.createdAt));
      },
    },
    {
      field: "organizations.name",
      headerName: "Company",
      sortable: false,
      flex: 1,
      filterOperators: getGridStringOperators().filter((op) => ["contains", "isAnyOf"].includes(op.value)),
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.organization.name;
      },
    },
    {
      field: "employeeAddressState",
      headerName: "To: State",
      sortable: false,
      flex: 1,
      filterOperators: getGridStringOperators().filter((op) => ["contains", "isAnyOf"].includes(op.value)),
    },
    {
      field: "locations.state",
      headerName: "Return: State",
      sortable: false,
      flex: 1,
      filterOperators: getGridStringOperators().filter((op) => ["contains", "isAnyOf"].includes(op.value)),
      renderCell: (params) => {
        return <Typography>{params.row.location?.state}</Typography>;
      },
    },
    {
      field: "state",
      headerName: "Status",
      sortable: false,
      flex: 2,
      filterOperators: getGridStringOperators().filter((op) => ["contains", "isAnyOf"].includes(op.value)),
      renderCell({ row }: GridRenderCellParams<any>) {
        return (
          <Chip
            label={row.state
              .split("_")
              .map((state: string) => state.toUpperCase())
              .join(" ")}
          />
        );
      },
    },
  ];

  return (
    <Box>
      <Box>
        <Typography variant="h4">Scan shipping label</Typography>
      </Box>
      <AppSelect
        fullWidth
        value={selectedDeviceId ?? deviceId}
        onChange={(e) => {
          setSelectedDeviceId(e.target.value);
        }}
      >
        {devices
          ?.filter(({ kind }) => kind === "videoinput")
          .map((device) => (
            <MenuItem key={device.deviceId} value={device.deviceId}>
              {device.label}
            </MenuItem>
          ))}
      </AppSelect>
      <Box sx={{ width: "400px" }}>
        <video ref={ref} />
      </Box>

      <Box>
        <Typography variant="h5">Or enter the tracking number</Typography>
      </Box>

      <Box>
        <Box
          component={"form"}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            navigateToPickup();
          }}
          sx={{ display: "flex", ".MuiInputBase-root": { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
        >
          <SearchInput
            placeholder="Search"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            width="none"
            sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            fullWidth
          />
          <Button
            type="submit"
            disableElevation
            variant="contained"
            sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            Search
          </Button>
        </Box>
      </Box>
      <Box my={4}>
        <Typography variant="h4">Inbound Shipping</Typography>
        <Typography mt={0.5}>Boxes that have been received but haven't been processed yet</Typography>
      </Box>
      <DataGridPro
        autoHeight
        sx={{ background: "background.paper" }}
        rows={assetRetrievals}
        getRowId={({ uuid }) => uuid}
        {...pageState}
        columns={columns}
        getDetailPanelHeight={() => "auto"}
        rowsPerPageOptions={[5, 25, 50, 100]}
        filterMode="server"
        rowCount={count}
        components={{
          LoadingOverlay: LinearProgress,
        }}
      />
    </Box>
  );
}
