import { GridFilterModel } from "@mui/x-data-grid";
import { GridFilterItem, GridLinkOperator } from "@mui/x-data-grid-pro";
import { snakeCase } from "lodash";
import { operators, operatorsThatRequireValue } from "components/DataGrid/helpers/operators";

function escapeSpecialCharacters(value: string | number, escapeWhitespace: boolean) {
  // + - = && || > < ! ( ) { } [ ] ^ " ~ * ? : \ /
  const escapedValue = value.toString().replaceAll(/([+\-=&|><!(){}\[\]^"~*?:\\/])/g, "\\$1");
  if (escapeWhitespace) return escapedValue.replaceAll(/\s+/g, "\\ ");
  return escapedValue;
}

function requiredItemWithEmptyValue(item: GridFilterItem) {
  if (operatorsThatRequireValue.includes(item.operatorValue || ""))
    return item.value !== undefined && item.value !== "";
  return true;
}

export function filterItemToQueryString(item: GridFilterItem) {
  const fieldName = snakeCase(item.columnField);
  const operator = operators.find((op) => op.operatorValue === item.operatorValue);
  if (!operator) return "";
  if (operator.multiValue) {
    return `${fieldName}:${operator.serializer(item.value.map((v: string) => escapeSpecialCharacters(v, operator.escapeWhitespace)))}`;
  } else {
    return `${fieldName}:${operator.serializer(escapeSpecialCharacters(item.value ?? "", operator.escapeWhitespace))}`;
  }
}

export function filterModelToElasticQueryString(filterModel: GridFilterModel): string {
  return filterModel.items
    .filter(requiredItemWithEmptyValue)
    .map(filterItemToQueryString)
    .join(filterModel.linkOperator === GridLinkOperator.And ? " AND " : " OR ");
}
