import { IndexedInventory } from "types/inventory";
import ReactToPrint from "react-to-print";
import Printable from "components/Printable";
import PrintableQRCodes from "components/Pickups/PrintableQRCodes";
import React, { useRef } from "react";
import { GridBulkActionsItem } from "components/DataGrid/behaviors/withBulkActions";
import PrintIcon from "@mui/icons-material/Print";
import { useGridApiContext } from "@mui/x-data-grid-pro";

// React Print requires us to initiate the print modal from the click directly, so we have to use a custom menu item
// vs dispatching an event.
export const GridBulkPrintActionItem = () => {
  const apiRef = useGridApiContext();
  const printComponentRef = useRef<HTMLDivElement | null>(null);
  const selectedInventories = Array.from(apiRef.current.getSelectedRows().values()) as IndexedInventory[];

  return (
    <>
      <ReactToPrint
        trigger={() => <GridBulkActionsItem label={"Print"} icon={<PrintIcon />} />}
        content={() => printComponentRef.current}
        onAfterPrint={() => {
          console.log({ selectedInventories });
          dispatchEvent(new CustomEvent("bulkPrint", { detail: { selectedRows: selectedInventories } }));
        }}
      />
      <Printable>
        <PrintableQRCodes selectedInventories={selectedInventories} ref={printComponentRef} />
      </Printable>
    </>
  );
};
