import { Box, Button, FormControl, InputAdornment, TextField } from "@mui/material";
import { InputLabel } from "components/common/Input";
import { Formik } from "formik";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "services/api.service";

export function RevivnProSoftwareTransactionGroupForm() {
  const { uuid, transactionGroupId } = useParams();
  const navigate = useNavigate();
  const [transactionGroup, setTransactionGroup] = useState<any>(null);

  useEffect(() => {
    if (!transactionGroupId) return;
    api.get(`/organizations/${uuid}/transaction_groups/${transactionGroupId}`).then(({ data }) => {
      setTransactionGroup(data);
    });
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          transactionGroup || {
            debitTransaction: "",
            quickbooksId: "",
            subscriptionStartDate: new Date(),
            subscriptionEndDate: new Date(),
          }
        }
        onSubmit={(values) => {
          api
            .post(`/organizations/${uuid}/transaction_groups`, {
              transactionGroup: { type: "RevivnProSoftware", ...values },
            })
            .then(({ data }) => {
              navigate(`/finance/clients/${uuid}/transaction_groups/RevivnProSoftware/${data.id}`, {
                replace: true,
              });
            });
        }}
      >
        {({ getFieldProps, handleSubmit }) => (
          <Box
            sx={{
              p: 4,
              mb: 4,
              background: "background.paper",
              borderRadius: 1,
              border: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Debit Amount:</InputLabel>
                <TextField
                  size="small"
                  {...getFieldProps("debitTransaction")}
                  disabled={!!transactionGroupId}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Quickbooks ID:</InputLabel>
                <TextField size="small" {...getFieldProps("quickbooksId")} disabled={!!transactionGroupId} />
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Subscription start date:</InputLabel>
                <TextField
                  id="name"
                  size="small"
                  type={transactionGroupId ? "" : "date"}
                  {...getFieldProps("subscriptionStartDate")}
                  disabled={!!transactionGroupId}
                />
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Subscription end date:</InputLabel>
                <TextField
                  id="name"
                  size="small"
                  type={transactionGroupId ? "" : "date"}
                  {...getFieldProps("subscriptionEndDate")}
                  disabled={!!transactionGroupId}
                />
              </FormControl>
            </Box>
            {!transactionGroupId && (
              <Box display="flex" justifyContent="flex-end" mt={6}>
                <Button variant="contained" onClick={() => handleSubmit()}>
                  Save
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Formik>
      {transactionGroupId && (
        <Formik
          enableReinitialize
          initialValues={
            transactionGroup || {
              creditTransaction: "",
              creditPostedAt: new Date(),
            }
          }
          onSubmit={({ creditTransaction, creditPostedAt }) => {
            api
              .put(`/organizations/${uuid}/transaction_groups/${transactionGroupId}`, {
                transactionGroup: { type: "RevivnProSoftware", creditTransaction, creditPostedAt },
              })
              .then(({ data }) => {
                setTransactionGroup(data);
              });
          }}
        >
          {({ getFieldProps, handleSubmit }) => (
            <Box
              sx={{
                p: 4,
                mb: 4,
                background: "background.paper",
                borderRadius: 1,
                border: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink>Credit Amount:</InputLabel>
                  <TextField
                    size="small"
                    {...getFieldProps("creditTransaction")}
                    disabled={transactionGroup && transactionGroup.creditTransaction}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink>Posted at:</InputLabel>
                  <TextField
                    size="small"
                    type={transactionGroup && transactionGroup.creditTransaction ? "" : "date"}
                    {...getFieldProps("creditPostedAt")}
                    disabled={transactionGroup && transactionGroup.creditTransaction}
                  />
                </FormControl>
              </Box>
              {!(transactionGroup && transactionGroup.creditTransaction) && (
                <Box display="flex" justifyContent="flex-end" mt={6}>
                  <Button variant="contained" onClick={() => handleSubmit()}>
                    Save
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Formik>
      )}
    </>
  );
}
