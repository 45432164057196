import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { AppSelect, InputLabel } from "components/common/Input";
import { Formik } from "formik";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "services/api.service";

export function LaptopRetrievalGroupOrderTransactionGroupForm() {
  const { uuid, transactionGroupId } = useParams();
  const [assetRetrievals, setAssetRetrievals] = useState([]);
  const [transactionGroup, setTransactionGroup] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/asset_retrievals?organization_uuid=${uuid}&page[size]=200`).then(({ data: { assetRetrievals } }) => {
      setAssetRetrievals(assetRetrievals);
    });
  }, []);

  useEffect(() => {
    if (!transactionGroupId) return;
    api.get(`/organizations/${uuid}/transaction_groups/${transactionGroupId}`).then(({ data }) => {
      setTransactionGroup(data);
    });
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          transactionGroup || {
            debitTransaction: "",
            quickbooksId: "",
            paymentMethod: "invoice",
            postedAt: new Date(),
            assetRetrievalOrderIds: [],
          }
        }
        onSubmit={(values) => {
          api
            .post(`/organizations/${uuid}/transaction_groups`, {
              transactionGroup: { type: "LaptopRetrievalGroupOrder", ...values },
            })
            .then(({ data }) => {
              navigate(`/finance/clients/${uuid}/transaction_groups/LaptopRetrievalGroupOrder/${data.id}`, {
                replace: true,
              });
            });
        }}
      >
        {({ getFieldProps, values, handleSubmit }) => (
          <Box
            sx={{
              p: 4,
              mb: 4,
              background: "background.paper",
              borderRadius: 1,
              border: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Payment Method:</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  {...getFieldProps("paymentMethod")}
                >
                  <FormControlLabel
                    value="earnings"
                    control={<Radio />}
                    label="Earnings"
                    disabled={!!transactionGroupId}
                  />
                  <FormControlLabel
                    value="invoice"
                    control={<Radio />}
                    label="Invoice"
                    disabled={!!transactionGroupId}
                  />
                  {transactionGroupId && (
                    <FormControlLabel value="stripe" control={<Radio />} label="Stripe" disabled />
                  )}
                </RadioGroup>
              </FormControl>
              <div />
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Debit Amount:</InputLabel>
                <TextField
                  size="small"
                  {...getFieldProps("debitTransaction")}
                  disabled={!!transactionGroupId}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </FormControl>
              {values.paymentMethod === "invoice" && (
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink>Quickbooks ID:</InputLabel>
                  <TextField size="small" {...getFieldProps("quickbooksId")} disabled={!!transactionGroupId} />
                </FormControl>
              )}
              {values.paymentMethod === "stripe" && (
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink>Stripe Payment ID:</InputLabel>
                  <TextField size="small" {...getFieldProps("stripePaymentIntentId")} disabled={!!transactionGroupId} />
                </FormControl>
              )}
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Posted at:</InputLabel>
                <TextField
                  size="small"
                  type={transactionGroupId ? "" : "date"}
                  {...getFieldProps("postedAt")}
                  disabled={!!transactionGroupId}
                />
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>Select associated orders:</InputLabel>
                <AppSelect {...getFieldProps("assetRetrievalOrderIds")} multiple disabled={!!transactionGroupId}>
                  {assetRetrievals.map((assetRetrieval: any) => (
                    <MenuItem key={assetRetrieval.uuid} value={assetRetrieval.uuid}>
                      {assetRetrieval.orderNumber}
                    </MenuItem>
                  ))}
                </AppSelect>
                {values.assetRetrievalOrderIds.length} orders selected.
              </FormControl>
            </Box>
            {!transactionGroupId && (
              <Box display="flex" justifyContent="flex-end" mt={6}>
                <Button variant="contained" onClick={() => handleSubmit()}>
                  Save
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Formik>
      {transactionGroup && transactionGroup.paymentMethod === "invoice" && (
        <Formik
          enableReinitialize
          initialValues={
            transactionGroup || {
              creditTransaction: "",
              creditPostedAt: new Date(),
            }
          }
          onSubmit={({ creditTransaction, creditPostedAt }) => {
            api
              .put(`/organizations/${uuid}/transaction_groups/${transactionGroupId}`, {
                transactionGroup: { type: "LaptopRetrievalGroupOrder", creditTransaction, creditPostedAt },
              })
              .then(({ data }) => {
                setTransactionGroup(data);
              });
          }}
        >
          {({ getFieldProps, handleSubmit }) => (
            <Box
              sx={{
                p: 4,
                mb: 4,
                background: "background.paper",
                borderRadius: 1,
                border: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink>Credit Amount:</InputLabel>
                  <TextField
                    size="small"
                    {...getFieldProps("creditTransaction")}
                    disabled={transactionGroup && transactionGroup.creditTransaction}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink>Posted at:</InputLabel>
                  <TextField
                    size="small"
                    type={transactionGroup && transactionGroup.creditTransaction ? "" : "date"}
                    {...getFieldProps("creditPostedAt")}
                    disabled={transactionGroup && transactionGroup.creditTransaction}
                  />
                </FormControl>
              </Box>
              {!(transactionGroup && transactionGroup.creditTransaction) && (
                <Box display="flex" justifyContent="flex-end" mt={6}>
                  <Button variant="contained" onClick={() => handleSubmit()}>
                    Save
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Formik>
      )}
    </>
  );
}
