import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { serializeSortModel } from "components/DataGrid/helpers/serializers";
import { filterModelToElasticQueryString } from "components/DataGrid/helpers/elasticSerializers";
import { getSearchParam } from "components/DataGrid/helpers/searchParams";

export interface DataGridParamsOptions {
  syncStateWithSearchParams?: boolean;
  defaultToMostRecentParams?: boolean;
}

interface Params {
  page: number;
  size: number;
  query: string;
  sort: string;
}

const DEFAULT_PARAMS: Params = { page: 1, size: 25, query: "", sort: "" };

// Here we make an assumption that if you are syncing with the URL, there is only one datagrid per page.
// Thus, you don't have to specify some sort of persistence key, as it uses the pathname of the page.
// This may not end up being true in the long term, so if this assumption doesn't hold, maybe add a key prop?
const getDatagridParamsPersistenceKey = () => `dataGrid@${window.location.pathname}.lastParams`;

// Selectors to get the sort, query, page, and page size from the initial state.
// ------
const getSort = (initialState: GridInitialStatePro | undefined) => {
  return initialState?.sorting?.sortModel ? serializeSortModel(initialState.sorting.sortModel) : undefined;
};
const getQuery = (initialState: GridInitialStatePro | undefined) => {
  return initialState?.filter?.filterModel
    ? filterModelToElasticQueryString(initialState.filter.filterModel)
    : undefined;
};
const getPageSize = (initialState: GridInitialStatePro | undefined) => {
  return initialState?.pagination?.pageSize;
};
const getPage = (initialState: GridInitialStatePro | undefined) => {
  return initialState?.pagination?.page;
};

// ------

function persistMostRecentParams(params: Params) {
  localStorage.setItem(getDatagridParamsPersistenceKey(), JSON.stringify(params));
}

const getMostRecentParams = (): Partial<Params> | null => {
  const key = getDatagridParamsPersistenceKey();
  const params = JSON.parse(localStorage.getItem(key) || "{}") as Params;
  return Object.values(params).every((value) => value === null) ? null : params;
};

function getInitialStateParams(initialState: GridInitialStatePro | undefined): Partial<Params> | null {
  const params = {
    sort: getSort(initialState),
    query: getQuery(initialState),
    size: getPageSize(initialState),
    page: getPage(initialState),
  };
  return Object.values(params).some((value) => value !== undefined) ? params : null;
}

function getInitialSearchParams(): Partial<Params> | null {
  const params = {
    sort: getSearchParam("sort"),
    query: getSearchParam("q"),
    size: getSearchParam("size") ? Number(getSearchParam("size")) : undefined,
    page: getSearchParam("page") ? Number(getSearchParam("page")) : undefined,
  };
  return Object.values(params).some((value) => value !== undefined) ? params : null;
}

// @ts-expect-error - any is expected here because we are using console debug statements.
function debug(...args) {
  if (getSearchParam("debugDatagrid")) console.debug(...args);
}

// Returns the Params for the datagrid, initialized from a variety of different sources depending on
// the options passed to the hook.
// This hook is also responsible for syncing the datagrid params with the URL search params and persisting
// the most recent params to local storage.
// - Because the initial state of the datagrid can come from different places,
//   You can pass a search param of `debugDatagrid=true` to the current page to see what state is being used.
export function useElasticDataGridParams(
  initialState: GridInitialStatePro | undefined,
  { syncStateWithSearchParams, defaultToMostRecentParams }: DataGridParamsOptions,
) {
  const navigate = useNavigate();

  const [params, setParams] = useState<Params>(() => {
    let initialValue: Partial<Params> | null = getInitialStateParams(initialState);
    debug(`Attempting to use initialState props...`, initialValue || "Not found");

    if (!initialValue && syncStateWithSearchParams) {
      initialValue = getInitialSearchParams();
      debug(`Attempting to use search params...`, initialValue || "Not found");
    }

    if (!initialValue && defaultToMostRecentParams) {
      initialValue = getMostRecentParams();
      debug(`Attempting to use most recent params...`, initialValue || "Not found");
    }

    const finalValue = {
      page: initialValue?.page || DEFAULT_PARAMS.page,
      size: initialValue?.size || DEFAULT_PARAMS.size,
      query: initialValue?.query || DEFAULT_PARAMS.query,
      sort: initialValue?.sort || DEFAULT_PARAMS.sort,
    };
    debug(`Filling in params with defaults`, finalValue);
    return finalValue;
  });

  useEffect(() => {
    if (!defaultToMostRecentParams) return;

    persistMostRecentParams(params);
  }, [defaultToMostRecentParams, params]);

  // Effect to persist the search params in the URL.
  // We can't use setSearchParams here because it is not referentially stable and will change on every render
  // https://github.com/remix-run/react-router/issues/9991
  useEffect(() => {
    if (!syncStateWithSearchParams) return;
    const { page, size, query, sort } = params;

    const searchParams = new URLSearchParams({ page: page.toString(), size: size.toString(), q: query, sort });
    if (query === DEFAULT_PARAMS.query) searchParams.delete("q");
    if (sort === DEFAULT_PARAMS.sort) searchParams.delete("sort");
    if (size === DEFAULT_PARAMS.size) searchParams.delete("size");
    if (page === DEFAULT_PARAMS.page) searchParams.delete("page");
    navigate(`?${searchParams}`);
  }, [navigate, params, syncStateWithSearchParams]);

  return [params, setParams] as const;
}
