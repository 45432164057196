import { Box, TextField } from "@mui/material";
import { LabeledField } from "components/Form/LabeledField";
type EditAddressFormProps = {
  formType: "employee" | "returnRecipient"; // Determines which form to render
};

export default function EditAddressForm({ formType }: EditAddressFormProps) {
  const isEmployeeForm = formType === "employee";

  return (
    <Box>
      <Box mt={3} sx={{ display: "flex", flexDirection: "column", gap: 2, alignContent: "center" }}>
        <Box sx={{ display: "grid", gridTemplateColumns: "2fr 2fr", gap: 2 }}>
          <LabeledField
            as={TextField}
            label="First Name"
            name={isEmployeeForm ? "employeeFirstName" : "returnRecipientFirstName"}
            disabled={isEmployeeForm ? true : false}
          />
          <LabeledField
            as={TextField}
            label="Last Name"
            name={isEmployeeForm ? "employeeLastName" : "returnRecipientLastName"}
            disabled={isEmployeeForm ? true : false}
          />
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "2fr 2fr", gap: 2 }}>
          <LabeledField
            as={TextField}
            label="Phone Number"
            name={isEmployeeForm ? "employeePhoneNumber" : "returnRecipientPhoneNumber"}
          />
          <LabeledField as={TextField} label="Email" name={isEmployeeForm ? "employeeEmail" : "returnRecipientEmail"} />
        </Box>
        <LabeledField
          as={TextField}
          label="Address"
          name={isEmployeeForm ? "employeeAddress" : "returnRecipientAddress"}
        />
        <LabeledField
          as={TextField}
          label="Address Line 2"
          name={isEmployeeForm ? "employeeAddressTwo" : "returnRecipientAddressTwo"}
        />
        <Box sx={{ display: "grid", gridTemplateColumns: "2fr 2fr", gap: 2 }}>
          <LabeledField
            as={TextField}
            label="City"
            name={isEmployeeForm ? "employeeAddressCity" : "returnRecipientAddressCity"}
          />
          <LabeledField
            as={TextField}
            label="State"
            name={isEmployeeForm ? "employeeAddressState" : "returnRecipientAddressState"}
          />
        </Box>
        <Box sx={{ display: "grid", gridTemplateColumns: "2fr 2fr", gap: 2 }}>
          <LabeledField
            as={TextField}
            label="Zip Code"
            name={isEmployeeForm ? "employeeAddressZipCode" : "returnRecipientAddressZipCode"}
          />
          <LabeledField
            as={TextField}
            label="Country"
            name={isEmployeeForm ? "employeeAddressCountry" : "returnRecipientAddressCountry"}
            disabled={true}
          />
        </Box>
      </Box>
    </Box>
  );
}
