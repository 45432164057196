import {
  GridColTypeDef,
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
} from "@mui/x-data-grid-pro";
import { Inventory } from "types/inventory";
import { AnySchema, ValidationError } from "yup";
import { Tooltip } from "@mui/material";

// This function returns a GridColTypeDef object that can be used to create a column definition for a DataGrid column.
// Unfortunately, you have to pass the field name as there's no way to get it from the props passed to the preprocess method.
export const editableColDefWithValidation = function (validationSchema: AnySchema, field: string): GridColTypeDef {
  return {
    editable: true,
    preProcessEditCellProps: (cellParams: GridPreProcessEditCellProps<Inventory>) => {
      try {
        validationSchema.validateSync({ ...cellParams.row, [field]: cellParams.props.value });
        return { ...cellParams.props, error: false };
      } catch (error) {
        if (error instanceof ValidationError) return { ...cellParams.props, error: error.errors.join(", ") };
        return { ...cellParams.props, error: true };
      }
    },
    renderEditCell: (cellParams: GridRenderEditCellParams<string>) => {
      return (
        <Tooltip open={!!cellParams.error} title={cellParams.error || ""}>
          <GridEditInputCell {...cellParams} />
        </Tooltip>
      );
    },
  };
};
