import React, { useState } from "react";
import { Alert, Box, Drawer, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IndexedInventory } from "types/inventory";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { Field } from "formik";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";
import { AxiosError } from "axios";
import { useAlert } from "hooks/useAlert";
import { useEventListener } from "hooks/useEventListener";
import { dispatchBulkActionComplete } from "components/DataGrid/behaviors/withBulkActions";

export const BulkEditAction = () => {
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<IndexedInventory[]>([]);
  const [error, setError] = useState<AxiosError | null>(null);

  useEventListener("bulkEdit", (event) => {
    setOpen(true);
    if (event instanceof CustomEvent) {
      const details = event.detail as { selectedRows: IndexedInventory[] };
      setSelectedRows(details.selectedRows);
    }
  });

  const { alertSuccess } = useAlert();

  function handleClose() {
    setOpen(false);
    setSelectedRows([]);
    setError(null);
  }

  return (
    <Drawer anchor="right" open={open} variant="temporary" onClose={handleClose}>
      <Box sx={{ width: 500, m: "20px" }}>
        <Grid container>
          <Grid item xs={10} sx={{ justifyContent: "flex-start", display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Bulk change</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={{ float: "right" }} size="large" onClick={handleClose}>
              <Close fontSize="medium" color="primary" />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex", alignItems: "center" }}>
            <Typography>{selectedRows.length} inventories selected</Typography>
            <Tooltip
              title={selectedRows.map((inventory) => {
                return inventory.id + "," + "\n";
              })}
            >
              <IconButton>
                <ArrowDropDownIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error.response?.data?.errors?.message}</Alert>
            </Grid>
          )}

          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography variant="overline">New location</Typography>
          </Grid>
          <Grid item xs={12}>
            <RevivnApiForm
              initialValues={{ warehouseLocation: null }}
              // eslint-disable-next-line
              onSubmit={(d: any) => {
                setError(null);
                const warehouseLocation = d.warehouseLocation;
                d.inventories = selectedRows.map((inventory) => ({
                  id: inventory.id,
                  warehouseLocation: warehouseLocation,
                }));
                delete d.warehouseLocation;
              }}
              action={"inventories/bulk/edit"}
              onError={(axiosError) => {
                setError(axiosError);
              }}
              onSuccess={() => {
                alertSuccess(
                  `Succesfully edited ${selectedRows.length} inventories. Your changes may take a few seconds to appear.`,
                );
                handleClose();
                dispatchBulkActionComplete();
              }}
              method={"PUT"}
            >
              <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                <Field name="warehouseLocation" as={TextField} variant="outlined" fullWidth />
                <SubmitAwareButton variant="contained" type="submit" sx={{ mx: 2 }}>
                  Move
                </SubmitAwareButton>
              </Box>
            </RevivnApiForm>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};
