import { ArrowBack } from "@mui/icons-material";
import { Box, Button, FormControl, MenuItem } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import { AppSelect, InputLabel } from "components/common/Input";
import useClients from "hooks/useClients";
import { useNavigate, useParams } from "react-router-dom";
import { EarningsDonationTransactionGroupForm } from "./TransactionGroupForms/EarningsDonationTransactionGroupForm";
import { RevivnDonationMatchTransactionGroupForm } from "./TransactionGroupForms/RevivnDonationMatchTransactionGroupForm";
import { EarningsWithdrawalTransactionGroupForm } from "./TransactionGroupForms/EarningsWithdrawalTransactionGroupForm";
import { EarningsCreditTransactionGroupForm } from "./TransactionGroupForms/EarningsCreditTransactionGroupForm";
import { DonationCreditTransactionGroupForm } from "./TransactionGroupForms/DonationCreditTransactionGroupForm";
import { RevivnProSoftwareTransactionGroupForm } from "./TransactionGroupForms/RevivnProSoftwareTransactionGroupForm";
import { LaptopRetrievalOrderTransactionGroupForm } from "./TransactionGroupForms/LaptopRetrievalOrderTransactionGroupForm";
import { PickupFeesTransactionGroupForm } from "./TransactionGroupForms/PickupFeesTransactionGroupForm";
import { LaptopRetrievalGroupOrderTransactionGroupForm } from "./TransactionGroupForms/LaptopRetrievalGroupOrderTransactionGroupForm";

const transactionGroupTypes = [
  "Earnings Donation",
  "Earnings Credit",
  "Donation Credit",
  "Revivn Donation Match",
  "Laptop Retrieval Order",
  "Laptop Retrieval Group Order",
  "Revivn Pro Software",
  "Earnings Withdrawal",
  "Pickup Fees",
];

export function TransactionGroupsEdit() {
  const { client } = useClients();
  const navigate = useNavigate();
  const { uuid, transactionType } = useParams();

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Finance", to: "/finance/pickups" },
          { label: "Clients", to: "/finance/clients" },
          { label: client ? client?.name : "", to: `/finance/clients/${client?.uuid}` },
          { label: "Edit Transaction" },
        ]}
      ></Breadcrumbs>
      <Box mt={4}>
        <Button
          size="small"
          variant="text"
          startIcon={<ArrowBack />}
          onClick={() => navigate(`/finance/clients/${uuid}/transaction_groups`)}
        >
          back to transactions
        </Button>
      </Box>
      <Box mt={4}></Box>
      <Box
        sx={{
          p: 4,
          mb: 4,
          background: "background.paper",
          borderRadius: 1,
          border: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="name">
            Select type of transaction
          </InputLabel>
          <AppSelect value={transactionType} disabled id="name">
            {transactionGroupTypes.map((transactionGroup) => (
              <MenuItem value={transactionGroup.split(" ").join("")} key={transactionGroup}>
                {transactionGroup}
              </MenuItem>
            ))}
          </AppSelect>
        </FormControl>
      </Box>
      {transactionType === "EarningsDonation" && <EarningsDonationTransactionGroupForm />}
      {transactionType === "EarningsCredit" && <EarningsCreditTransactionGroupForm />}
      {transactionType === "DonationCredit" && <DonationCreditTransactionGroupForm />}
      {transactionType === "RevivnProSoftware" && <RevivnProSoftwareTransactionGroupForm />}
      {transactionType === "LaptopRetrievalOrder" && <LaptopRetrievalOrderTransactionGroupForm />}
      {transactionType === "LaptopRetrievalGroupOrder" && <LaptopRetrievalGroupOrderTransactionGroupForm />}
      {transactionType === "RevivnDonationMatch" && <RevivnDonationMatchTransactionGroupForm />}
      {transactionType === "EarningsWithdrawal" && <EarningsWithdrawalTransactionGroupForm />}
      {transactionType === "PickupFees" && <PickupFeesTransactionGroupForm />}
    </Box>
  );
}
