import React from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

export function PicklistCard({
  title,
  Icon,
  children,
}: {
  title: string;
  Icon: SvgIconComponent;
  children: React.ReactNode;
}) {
  return (
    <Card variant="outlined">
      <CardContent sx={{ backgroundColor: (theme) => theme.palette.grey["100"] }} style={{ paddingBottom: "1rem" }}>
        <Stack alignItems="center" gap={1.5}>
          <Typography alignItems={"center"} display="flex" gap={1} textTransform="uppercase" color="text.secondary">
            <Icon fontSize={"small"} /> {title}
          </Typography>
          {children}
        </Stack>
      </CardContent>
    </Card>
  );
}
