import { Box, Chip, styled, Typography, IconButton } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  GridRowParams,
  GridValidRowModel,
  GridValueGetterParams,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import useDonationRequests from "hooks/useDonationRequests";
import { isValidElement, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useConfirmation } from "hooks/useConfirmation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import { DonationRequest } from "types/donationRequest";
import DonationHistoryDrawer from "./DonationHistoryDrawer";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  height: "80vh",
  backgroundColor: "background.paper",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.mode == "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
  },
}));

const CustomDetailPanelToggle = (props: Pick<GridRenderCellParams, "id" | "value">) => {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);
  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton size="small" tabIndex={-1} disabled={!hasDetail} aria-label={isExpanded ? "Close" : "Open"}>
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
};

export default function DonationRequestsTable() {
  const { donationRequests, deleteDonationRequest } = useDonationRequests();
  const navigate = useNavigate();
  const { confirm } = useConfirmation();
  const pageState = useServerSideDataGridParams(donationRequests);

  const getDetailPanelContent = useCallback(
    ({ row: donationRequest }: GridRowParams<GridValidRowModel>) => (
      <DonationHistoryDrawer donations={donationRequest.donations} />
    ),
    [],
  );

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <IconButton
              sx={{ opacity: "80%" }}
              size="small"
              onClick={() => {
                confirm({
                  message: `Are you sure you want to delete this donation request?"`,
                  handler: () => {
                    deleteDonationRequest(params.row.uuid);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              sx={{ opacity: "80%" }}
              size="small"
              onClick={() => {
                navigate(`/donation-requests/${params.row.uuid}`);
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "benefitingOrganization.name",
      headerName: "Benefiting Organization",
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.benefitingOrganization.name,
    },
    {
      field: "donationRequest.status",
      headerName: "Status",
      width: 125,
      renderCell: (params: GridRenderCellParams<DonationRequest, DonationRequest>) => {
        return (
          <Chip
            label={params.row.status}
            sx={{
              backgroundColor: params.row.status === "in_process" ? "#FFF7EB" : "#EDF6ED",
              color: params.row.status === "in_process" ? "#FFB547" : "#4CAF50",
            }}
          />
        );
      },
    },
    {
      field: "donationRequest.email",
      headerName: "Email",
      width: 200,
      valueGetter: (params: GridValueGetterParams<DonationRequest, DonationRequest>) => params.row.mainContactEmail,
    },
    {
      field: "donationRequest.phoneNumber",
      headerName: "Phone Number",
      width: 200,
      valueGetter: (params: GridValueGetterParams<DonationRequest, DonationRequest>) =>
        params.row.mainContactPhoneNumber,
    },
    {
      field: "address",
      headerName: "Shipping Address",
      width: 400,
      sortable: false,
      renderCell: (params: GridRenderCellParams<DonationRequest, DonationRequest>) => {
        return (
          <Box>
            <Typography>
              {params.row.address}, {params.row.address2}
            </Typography>
            <Typography>
              {params.row.city} {params.row.zipcode} {params.row.state} {params.row.country}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "donationRequest.goalInMac",
      headerName: "MAC Goal",
      width: 125,
      valueGetter: (params: GridValueGetterParams<DonationRequest, DonationRequest>) => params.row.goalInMac,
    },
    {
      field: "donationRequest.goalInPc",
      headerName: "PC Goal",
      width: 125,
      valueGetter: (params: GridValueGetterParams<DonationRequest, DonationRequest>) => params.row.goalInPc,
    },
    {
      field: "donationRequest.goalInChromebook",
      headerName: "Chromebook Goal",
      width: 125,
      valueGetter: (params: GridValueGetterParams<DonationRequest, DonationRequest>) => params.row.goalInChromebook,
    },
    {
      field: "donationRequest.totalRequestedAmountInDollars",
      headerName: "Total Amount Requested",
      width: 125,
      valueGetter: (params: GridValueGetterParams<DonationRequest, DonationRequest>) =>
        params.row.totalRequestedAmountInDollars,
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
    },
  ];

  return (
    <StyledDataGrid
      sx={{ border: "none", mx: 3, mb: 3 }}
      rows={donationRequests}
      columns={columns}
      rowsPerPageOptions={[5, 10, 25]}
      disableColumnFilter
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => "auto"}
      {...pageState}
      initialState={{
        pinnedColumns: { right: [GRID_DETAIL_PANEL_TOGGLE_FIELD] },
      }}
    />
  );
}
