import { TextField, Tooltip, Typography } from "@mui/material";
import { GridColTypeDef, GridValueGetterParams, GridFilterInputValueProps } from "@mui/x-data-grid-pro";
import { ChangeEvent, useState } from "react";

const daysInThePast = (dateString: string) => {
  const dayInMiliseconds = 24 * 60 * 60 * 1000;
  const date = new Date(dateString);
  const currentDate = new Date();
  const diffInMiliseconds: number = currentDate.getTime() - date.getTime();

  return Math.floor(diffInMiliseconds / dayInMiliseconds);
};

const GridFilterDaysValue = (props: GridFilterInputValueProps) => {
  const { item, applyValue, apiRef, focusElementRef, ...fieldProps } = props;
  const [filterValueState, setFilterValueState] = useState<string>(
    item.value ? daysInThePast(item.value).toString() : "",
  );

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const date = new Date();
    const daysAgo = parseInt(event.target.value);

    if (isNaN(daysAgo)) {
      setFilterValueState("");
      return applyValue({ ...item, value: "" });
    }

    date.setDate(date.getDate() - daysAgo);
    setFilterValueState(daysAgo.toString());

    const filterDate = date.toISOString().split("T")[0];
    applyValue({ ...item, value: filterDate });
  };

  return (
    <TextField
      type="number"
      label={"Value"}
      variant={"standard"}
      onChange={handleFilterChange}
      value={filterValueState}
      placeholder="Filter value"
      InputLabelProps={{ shrink: true }}
      InputProps={{ inputProps: { min: 0 } }}
      {...fieldProps}
    />
  );
};

const daysCountFilterOperators = [
  {
    field: "",
    label: "=",
    value: "=",
    InputComponent: GridFilterDaysValue,
    getApplyFilterFn: () => null,
  },
  {
    field: "",
    label: "!=",
    value: "not",
    InputComponent: GridFilterDaysValue,
    getApplyFilterFn: () => null,
  },
  {
    field: "",
    label: ">",
    value: "<",
    InputComponent: GridFilterDaysValue,
    getApplyFilterFn: () => null,
  },
  {
    field: "",
    label: ">=",
    value: "<=",
    InputComponent: GridFilterDaysValue,
    getApplyFilterFn: () => null,
  },
  {
    field: "",
    label: "<",
    value: ">",
    InputComponent: GridFilterDaysValue,
    getApplyFilterFn: () => null,
  },
  {
    field: "",
    label: "<=",
    value: ">=",
    InputComponent: GridFilterDaysValue,
    getApplyFilterFn: () => null,
  },
];

export const daysCountColDef: GridColTypeDef = {
  renderCell: (params: GridValueGetterParams) => {
    if (!params.value) return "";

    const rowDate = params.value.split("T")[0];
    const daysAgo = daysInThePast(rowDate);

    return (
      <Tooltip title={new Date(params.value).toString()}>
        <Typography>{daysAgo}</Typography>
      </Tooltip>
    );
  },
  filterOperators: daysCountFilterOperators,
};
